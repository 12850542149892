.heroSection {
	background-image: url(../../assets/Home/taxSystemBgMobile.svg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 183px 20px 36px 20px;
	color: #fff;
}

.heroSection h2 {
	font-size: 30px;
	font-weight: 700;
}

.desktop {
	display: none;
}

.taxSystemMobileText {
	display: block;
}

.heroSection p {
	font-size: 14px;
	line-height: 24px;
	font-size: 400;
}

@media (min-width: 768px) {
	.heroSection {
		padding: 198px 0 80px 50px;
	}

	.heroSection p {
		font-size: 18px;
	}
}

@media (min-width: 1024px) {
	.heroSection {
		height: 413px;
		padding: 198px 0 80px 118px;
		background-image: url(../../assets/Home/TaxSystemBg.svg);
	}

	.heroSection h2 {
		font-size: 48px;
	}

	.desktop {
		display: block;
	}

	.mobile {
		display: none;
	}

	.taxSystemMobileText {
		display: none;
	}
}

/* heroSection Ended  */

/* System of Tax */
.systemOfTax {
	padding: 100px 20px 42px 20px;
	display: flex;
	flex-direction: column;
}

.systemOfTax img {
	width: 100%;
}

.paragraphs h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 22px;
	color: rgba(12, 12, 12, 1);
	margin-top: 32px;
}

.paragraphs h3 {
	font-size: 18px;
	font-weight: 600;
	color: #195130;
	margin-top: 32px;
}

.paragraphs p {
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.6;
	margin-top: 10px;
	color: #202020;
}

.underlineGreen {
	border-bottom: 4px solid var(--green-03);
	padding-bottom: 8px;
}

@media (min-width: 768px) {
	.systemOfTax {
		padding: 95px 118px;
		flex-direction: column;
		gap: 20px;
	}

	.paragraphs h1 {
		font-size: 28px;
	}

	.paragraphs h3 {
		font-size: 20px;
		margin-top: 30px;
	}

	.paragraphs p {
		font-size: 15px;
		margin-top: 15px;
	}
}

@media (min-width: 1024px) {
	.systemOfTax {
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 95px 118px;
	}

	.systemOfTax img {
		width: 45%;
	}

	.paragraphs {
		width: 50%;
	}

	.paragraphs h1 {
		font-size: 32px;
	}

	.paragraphs h3 {
		font-size: 22px;
	}

	.paragraphs p {
		font-size: 16px;
	}
}

/* System of Tax Ended */

/* governmentCards */
.governmentCards {
	padding: 50px 0;
	background-color: #f9f9f9;
}

.governmentCards h2 {
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	color: rgba(12, 12, 12, 1);
}

.description {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-top: 16px;
	color: #202020;
	padding: 0 20px;
}

.mobile {
	padding: 0 20px;
}

.governmentCard {
	margin-top: 20px;
}

.localState {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 30px;
}

.state {
	display: flex;
	flex-direction: row;
}

.state p {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: #202020;
}

.text {
	margin-left: 5px;
}

.left,
.right {
	background-color: #edf3f7;
	padding: 20px;
}

.right {
	background-color: #ffffff;
}

.left h3,
.right h3 {
	font-size: 18px;
	font-weight: 600;
	color: rgba(25, 81, 48, 1);
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.governmentCards {
		padding: 40px 60px;
		text-align: left;
	}

	.governmentCards h1 {
		font-size: 28px;
	}

	.description {
		font-size: 15px;
		margin: 30px 0;
		max-width: 80%;
	}

	.localState {
		flex-direction: row;
	}

	.left,
	.right {
		width: 48%;
	}

	.state p {
		font-size: 15px;
	}

	.left h3,
	.right h3 {
		font-size: 20px;
	}
}

@media (min-width: 1024px) {
	.governmentCards {
		padding: 112px 118px 94px 118px;
	}

	.governmentCards h2 {
		font-size: 28px;
	}
	.description {
		font-size: 16px;
		max-width: 572px;
		margin-top: 40px;
		padding: 0;
	}

	.localState {
		margin-top: 50px;
	}

	.left,
	.right {
		width: 590px;
		padding: 25px;
	}

	.state p {
		font-size: 16px;
	}
}

/* governmentCards Ended */
