.coupon__details > div {
	display: flex;
	column-gap: 10px;
	margin-bottom: 8px;
	font-size: 14px;
	line-height: 23px;
}

.coupon__details > div > .key {
	color: var(--grey-08);
	flex-basis: 160px;
}

.coupon__details > div > .value {
	font-weight: 600;
	color: var(--grey-10);
}

.pending {
	color: var(--pending) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.pending__approval {
	color: var(--green-01) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.approved {
	color: var(--green-02) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.denied {
	color: var(--red-02) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.table__header {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	color: var(--grey-10);
	margin-bottom: 24px;
	margin-top: 40px;
}

.approve__btns_div {
	margin-top: 64px;
	margin-bottom: 40px;
}

.confirm__print_btn {
	padding: 0 20px;
	margin-top: 45px;
	float: right;
}

/* Coupon sale styles */
.header {
	font-size: 24px;
	line-height: 29px;
	font-weight: 600;
	color: var(--grey-09);
	margin: 0;
	margin-bottom: 16px;
}

.sale__submit_btn {
	display: block;
	width: 194px;
	margin-top: 8px;
	margin-left: auto;
	margin-bottom: 30px;
}

.calculation__details {
	margin-top: 24px;
	margin-bottom: 12px;
}

.calculation__details > div {
	margin-bottom: 20px;
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
}

.calculation__details > div span:last-of-type {
	color: var(--grey-08);
}

.sale__bill_btns {
	margin: 20px 0;
	display: flex;
	justify-content: space-between;
}

.sale__bill_btns button {
	padding: 0 20px;
}
/* end of coupon sale styles */

@media (max-width: 768px) {
	.confirm__print_btn {
		width: calc(100% - 16px);
		float: unset;
	}

	.sale__submit_btn {
		width: 100%;
	}
}
