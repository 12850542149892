.subscription {
	padding: 84px 20px;
	background-image: url(../../assets/Home/subMobileBg.svg);
	background-size: cover;
	background-repeat: no-repeat;
}

.subscription h2 {
	font-size: 25px;
	text-transform: uppercase;
	font-weight: 700;
	color: rgba(58, 189, 110, 1);
	margin: 0;
}

.subscription p {
	color: rgba(255, 255, 255, 1);
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	margin-top: 16px;
	margin-bottom: 32px;
}

.inputAreaMobile {
	display: flex;
	flex-direction: column;
}

.inputAreaMobile input {
	width: 100%;
	height: 47px;
	border-radius: 4px;
}

.inputAreaMobile input:focus,
.inputAreaMobile input:hover {
	outline: none;
	border: none;
}

.inputAreaMobile input::placeholder {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.inputAreaMobile button {
	width: 100%;
	height: 47px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	background-color: rgba(58, 189, 110, 1);
	border: none;
	color: rgba(255, 255, 255, 1);
	cursor: pointer;
	margin-top: 16px;
}

.inputAreaMobile button:hover,
.inputAreaMobile button:active {
	box-shadow: none;
}

.inputAreaMobile button img {
	margin-left: 8px;
	margin-top: 3px;
}

@media (min-width: 768px) {
	.subscription {
		padding: 40px;
		height: 300px;
	}

	.subscription h2 {
		font-size: 22px;
	}

	.subscription p {
		font-size: 16px;
		line-height: 24px;
		max-width: 444px;
	}

	.inputAreaMobile {
		flex-direction: row;
	}

	.inputAreaMobile input {
		max-width: 348px;
		border-radius: 4px 0px 0px 4px;
	}

	.inputAreaMobile button {
		max-width: 144px;
		margin-top: 0;
		border-radius: 0px 4px 4px 0px;
	}
}

@media (min-width: 1024px) {
	.subscription {
		height: 393px;
		padding: 112px 118px;
		background-image: url(../../assets/Home/subBg.svg);
	}

	.subscription h2 {
		font-size: 24px;
	}
}
