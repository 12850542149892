.heroSection {
	background-image: url(../../assets/Home/taxCollectionBgMobile.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 313px;
	padding: 135px 20px 36px 20px;
	color: rgba(255, 255, 255, 1);
}

.heroSection h2 {
	font-size: 30px;
	font-weight: 700;
}

.heroSection p {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

.desktop {
	display: none;
}

.mobile {
	display: block;
}

/* heroSection Ended */
.underlineGreen {
	border-bottom: 4px solid var(--green-03);
	padding-bottom: 8px;
}

/* governmentCards */
.governmentCards {
	background-color: #f9f9f9;
}

.governmentCards h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 1.2;
	color: rgba(12, 12, 12, 1);
	padding: 0 20px;
	margin-top: 64px;
}

.governmentCard {
	margin-top: 20px;
}

.localState {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 40px;
}

.state {
	display: flex;
	flex-direction: row;
	color: #202020;
}

.state p {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.text {
	margin-left: 5px;
}

.left,
.right,
.leftBottom {
	width: 100%;
	background-color: #edf3f7;
	padding: 20px;
	line-height: 24px;
}

.right {
	background-color: #ffffff;
}

.left h3,
.right h3,
.leftBottom h3 {
	font-size: 17px;
	font-weight: 600;
	color: rgba(25, 81, 48, 1);
	margin-bottom: 10px;
}

.leftBottom {
	margin-top: 40px;
}

@media (min-width: 768px) {
	.heroSection {
		background-image: url(../../assets/Home/taxCollectionBgImg.svg);
		height: 400px;
		padding: 180px 0 0 50px;
	}

	.heroSection h2 {
		font-size: 48px;
		line-height: 58.09px;
	}

	.heroSection p {
		font-size: 16px;
		line-height: 25.89px;
	}

	.desktop {
		display: block;
	}
	.mobile {
		display: none;
	}

	.governmentCards {
		padding: 40px 60px;
	}

	.governmentCards h2 {
		font-size: 28px;
		text-align: center;
	}

	.localState {
		flex-direction: row;
		margin-top: 64px;
	}

	.left,
	.right,
	.leftBottom {
		width: 48%;
	}

	.state p {
		font-size: 15px;
	}

	.left h3,
	.right h3,
	.leftBottom h3 {
		font-size: 20px;
	}
}

@media (min-width: 1024px) {
	.governmentCards {
		padding: 3% 120px;
	}

	.left,
	.right,
	.leftBottom {
		width: 590px;
		padding: 25px;
	}

	.state p {
		font-size: 16px;
	}
}
