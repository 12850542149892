.accordion {
	background-color: rgba(25, 81, 48, 1);
	padding: 80px 118px 100px;
}

.heading {
	font-size: 48px;
	font-weight: 600;
	line-height: 58.09px;
	color: #f78c01;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: rgba(255, 255, 255, 1);
	cursor: pointer;
}

.title h2 {
	font-size: 24px;
	font-weight: 400;
	line-height: 29.05px;
	letter-spacing: -0.008em;
	margin-top: 32px;
}

.content {
	overflow: hidden;
	max-width: 1000px;
	max-height: 0;
	transition: all 0.5s cubic-bezier(0, 1, 0, 1);
	color: rgba(255, 255, 255, 0.8);
	font-size: 20px;
	font-weight: 400;
	line-height: 29.12px;
	margin-top: 10px;
}

.content .optionTitle {
	font-weight: 800;
}

.content .optionSteps {
	margin-left: 30px;
}

.contentShow {
	height: auto;
	max-height: 9999px;
	transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.partition {
	border: 1px solid rgba(212, 242, 224, 0.2);
	margin-top: 32px;
}

.faqBtnWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 64px;
}

.faqBtn {
	font-size: 145px;
	padding: 12px 48px;
	color: rgba(47, 153, 89, 1);
	border: rgba(47, 153, 89, 1);
	border-radius: 4px;
	align-items: center;
}

.faqBtn img {
	margin-left: 6px;
}

.faqLink:hover {
	color: #ffff;
}

/* /////////////////////News style///////////////////// */
.enuguTaxNewsHere {
	padding: 120px 118px;
}

.underlineGreen {
	border-bottom: 5px solid green;
	padding-bottom: 8px;
}

.newsCards {
	display: flex;
	justify-content: space-between;
	margin-top: 64px;
}

.newsCard {
	border: 1px solid rgba(236, 236, 236, 1);
	padding: 24px 20px 32px 20px;
}

.authDate {
	width: 205px;
	display: flex;
	justify-content: space-between;
	margin-top: 24px;
	padding: -10px;
}
.auth {
	display: flex;
	align-items: center;
	gap: 6px;
}

.auth img,
.date img {
	width: 12px;
	height: 12px;
}

.auth h6,
.date h6 {
	font-weight: 400;
	font-size: 12px;
	color: #7e7e7e;
}

.date {
	display: flex;
	align-items: center;
	gap: 6px;
}

.authDate .vertical {
	border-right: 2px solid #d8d8d8;
	height: 33px;
	left: 50%;
}

.desktopsNewsHeading {
	text-align: center;
	font-size: 28px;
	font-weight: 700;
	line-height: 44.89px;
	letter-spacing: -0.008em;
	color: rgba(0, 0, 0, 1);
}

.mobileNewsHeading {
	display: none;
}

.headline {
	width: 350px;
	font-size: 18px;
	font-weight: 600;
	line-height: 30.9px;
	color: rgba(25, 81, 48, 1);
}

.newsContent {
	width: 350px;
	font-size: 14px;
	font-weight: 400;
	line-height: 25.65px;
	text-align: left;
}

.link {
	margin-top: 30px;
	font-size: 16px;
	font-weight: 500;
	line-height: 22.7px;
	letter-spacing: 0.003em;
	color: rgba(0, 163, 255, 1);
}

.link a {
	color: #00a3ff;
}

.newsBtn {
	display: flex;
	justify-content: center;
	margin-top: 64px;
}

.newsBtn button {
	font-size: 14px;
	border: none;
	padding: 12px 50px;
	color: rgba(255, 255, 254, 1);
	background-color: rgba(47, 153, 89, 1);
	border-radius: 4px;
}

.newsBtn img {
	width: 25px;
	margin-left: 12px;
}

@media (max-width: 768px) {
	.accordion {
		padding: 100px 20px;
	}

	.heading {
		font-size: 24px;
	}

	.title h2 {
		font-size: 18px;
		margin-top: 24px;
	}

	.faqBtnWrapper {
		margin-top: 32px;
	}

	.partition {
		margin-top: 24px;
	}

	/* /////////////////////News style///////////////////// */

	.enuguTaxNewsHere {
		padding: 0 20px;
	}
	.newsCards {
		width: 100%;
		display: block;
		margin-top: 52px;
	}

	.newsCard {
		margin-top: 48px;
		padding: 0;
		border: none;
	}

	.newsCard img {
		width: 100%;
	}

	/* ////////// */
	.authDate {
		width: 205px;
		display: flex;
		justify-content: space-between;
		margin-top: 24px;
		padding: -10px;
	}
	.auth {
		display: flex;
		align-items: center;
		gap: 6px;
	}

	.auth img,
	.date img {
		width: 15px;
		height: 15px;
	}

	.auth h6,
	.date h6 {
		font-weight: 400;
		font-size: 12px;
		color: #7e7e7e;
	}

	.date {
		display: flex;
		align-items: center;
		gap: 6px;
	}

	.authDate .vertical {
		border-right: 2px solid #d8d8d8;
		height: 28px;
		left: 50%;
	}

	/* ////////// */
	.newsHeading {
		font-size: 30px;
		line-height: 30.89px;
	}

	.newsContent {
		width: 100%;
		font-size: 22px;
		margin-top: 20px;
	}

	.headline {
		width: 320px;
		font-size: 16px;
	}

	.newsContent {
		font-size: 18px;
	}

	.desktopsNewsHeading {
		display: none;
	}

	.mobileNewsHeading {
		display: block;
		font-size: 20px;
		text-align: center;
		padding: 5%;
		font-weight: 800;
	}

	.newsBtn {
		margin-top: 40px;
		margin-bottom: 104px;
	}

	.newsBtn button {
		padding: 16px;
		width: 100%;
	}

	.newsBtn img {
		width: 20px;
	}
}
