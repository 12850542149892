.aside {
	width: 30%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: var(--green-10);
}

.aside img:first-of-type {
	display: block;
	margin: 40px 0 65px 40px;
}

.aside p {
	font-size: 20px;
	color: #fff;
	max-width: 323px;
	margin: 0 0 52px 40px;
}

.aside img:last-of-type {
	width: 100%;
}

@media (max-width: 768px) {
	.no__display {
		display: none;
	}

	.aside {
		position: static;
		width: 100%;
		height: fit-content;
	}

	.aside img:first-of-type {
		margin: 29px auto 19px;
	}

	.aside p {
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		max-width: 229px;
		margin: 0 auto 41px;
	}

	.aside img:last-of-type {
		display: none;
	}
}
