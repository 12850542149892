.h2 {
	color: var(--grey-09);
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	letter-spacing: -3%;
	margin-bottom: 16px;
}

.instruction {
	font-size: 16px;
	font-weight: 400;
	line-height: 25.89px;
	margin: 0 0 12px;
	color: #000;
}

.checkbox__div {
	display: flex;
	column-gap: 24px;
	margin-bottom: 24px;
}

.checkbox__div > div {
	display: flex;
	align-items: center;
	column-gap: 8px;
}

.checkbox__div > div > input {
	height: 24px;
	width: 24px;
	margin: 0;
}

.checkbox__div > div > label {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
}

.submit__btn {
	display: block;
	width: 160px;
	margin-top: 8px;
	margin-bottom: 50px;
	margin-left: auto;
}

@media (max-width: 768px) {
	.submit__btn {
		width: 100%;
	}
}
