.header {
	padding: 16px 40px;
	padding-left: 0;
	display: flex;
	justify-content: space-between;
	height: 86px;
	width: calc(100% - 80px);
	margin: auto;
	border-bottom: 1px solid var(--diluted-primary-01);
}

.header > button:first-child,
.header > img:last-child {
	display: none;
}

.profile__information {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin-right: 16px;
	align-items: flex-end;
}

.nav {
	display: flex;
	align-items: center;
	flex: 9;
	justify-content: flex-end;
}

.profile__toggle {
	width: 42px;
	height: 42px;
	border-radius: 50%;
	border: none;
	background-color: transparent;
}

.profile__toggle img {
	height: 100%;
	width: 100%;
	border-radius: inherit;
}

.profile__toggle:hover {
	box-shadow: none;
}

.logout__modal {
	position: absolute;
	text-align: left;
	background-color: #fff;
	box-shadow: 4px 20px 20px #f2f2f2;
	border-radius: 4px;
	color: var(--alerts-red);
	font-size: 16px;
	height: 40px;
	width: 150px;
	right: 80px;
	top: 65px;
	z-index: 2;
}

.logout__modal button:hover {
	box-shadow: none;
}

.bold__text {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: var(--dark-blue-09);
}

.regular__text {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--light-grey-04);
	opacity: 0.5;
}

.title {
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	color: var(--light-grey-07);
}

@media (max-width: 768px) {
	.header {
		align-items: center;
		background-color: var(--dark-blue-07);
		height: 48px;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		padding-right: 16px;
		padding-left: 19.75px;
	}

	.title {
		font-size: 14px;
		line-height: 23px;
		color: var(--lights-01);
	}

	.title > img {
		display: none;
	}

	.header > button:first-child,
	.header > img:last-child {
		display: block;
	}

	.header > img:last-child {
		height: 40px;
	}

	.header > button:first-child {
		border: none;
		background-color: transparent;
	}

	.nav {
		display: none;
	}
}
