.success__page {
	display: flex;
}

.success {
	margin-left: 30%;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.success img {
	margin-bottom: 24px;
}

.success h1 {
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	color: var(--green-09);
	margin: 0;
}

.success p {
	font-weight: 300;
	line-height: 24px;
	margin: 12px auto 24px;
	color: var(--light-grey-08);
	text-align: center;
	max-width: 400px;
}

.success p span {
	font-weight: 600;
	color: var(--green-02);
}

.btn__area {
	display: flex;
	gap: 10px;
}

.success button {
	width: 200px;
}

.get__ecard_area {
	position: relative;
}

.modes {
	position: absolute;
	top: 47px;
	border-radius: 8px;
	box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.18);
}

.modes button {
	background: none;
	border: none;
	height: 30px;
}

.modes button:hover {
	box-shadow: none;
	color: var(--green-02);
}

@media (max-width: 768px) {
	.success__page {
		flex-direction: column;
	}

	.success {
		margin: 0;
	}

	.success p {
		max-width: 300px;
	}

	.btn__area {
		flex-direction: column;
	}
}
