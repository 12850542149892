.alert {
	position: fixed;
	top: 0;
	left: 5%;
	right: 5%;
	margin: auto;
	width: fit-content;
	text-align: center;
	padding: 0 30px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	border-radius: 2px;
	font-size: 15px;
}

.success {
	color: #fff;
	background-color: seagreen;
}

.error {
	background-color: #feebea;
	color: var(--alerts-red);
}

.warning {
	background-color: var(--primary-05);
	color: #fff;
}
