.settings__page {
	padding: 39px;
}

.card {
	background: var(--neutrals-white);
	padding: 0 24px;
	max-width: 876px;
	margin-bottom: 48px;
}

.card__top {
	display: flex;
	height: 90px;
	align-items: center;
	justify-content: space-between;
}

.bordered {
	border-bottom: 1px solid var(--primary-01);
}

.card__top > h5 {
	margin: 0;
	font-weight: bold;
	font-size: 18px;
	line-height: 29px;
	color: var(--light-grey-08);
}

.card__top > button {
	background-color: transparent;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 28px;
	width: 28px;
}

.card__top > button:hover {
	box-shadow: none;
}

.idDeetsForm {
	padding-top: 36px;
	padding-bottom: 32px;
}

.input__container,
.inputs__div {
	display: flex;
	justify-content: space-between;
	margin-bottom: 28px;
}

.input__container > div,
.inputs__div > div {
	width: calc(100% - 18px);
}

.input__container > div:first-child,
.inputs__div > div:first-child {
	margin-right: 18px;
}

.input__container > div:last-child,
.inputs__div > div:last-child {
	margin-left: 18px;
}

.inputs__div {
	display: flex;
	padding-bottom: 36px;
	margin-bottom: 36px;
	border-bottom: 1px solid var(--diluted-primary-01);
}

.inputs__div div.single__input__div {
	margin-left: 0;
	margin-right: 0;
	width: calc(50% - 18px);
}

.anssid__form > div.last {
	border-bottom: none;
	margin-bottom: 56px;
}

.input__container label,
.inputs__div label {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
}

.input__container label span,
.inputs__div label span {
	color: red;
}

.input__container input,
.inputs__div input,
.inputs__div select {
	width: 100%;
	background: transparent;
}

.idDeetsForm .addNew__btn,
.idDeetsForm .cancelEdit__btn,
.each__record .edit__btn,
.each__record .deactivate__btn {
	background-color: transparent;
	border: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	padding: 0 10px;
	color: var(--primary-01);
	margin-bottom: 24px;
}

.each__record .edit__btn {
	color: var(--primary-05);
	font-size: 15px;
	border-right: 1px solid var(--primary-05);
	margin-bottom: 0;
}

.each__record .edit__btn:hover {
	border-bottom: 1px solid var(--primary-05);
}

.each__record .deactivate__btn {
	color: var(--alerts-red);
	font-size: 15px;
	margin-bottom: 0;
}

.each__record .deactivate__btn:hover {
	border-bottom: 1px solid var(--alerts-red);
}

.idDeetsForm .addNew__btn:hover,
.idDeetsForm .cancelEdit__btn,
.each__record .edit__btn:hover,
.each__record .deactivate__btn:hover {
	box-shadow: none;
}

.idDeetsForm .addNew__btn:disabled,
.idDeetsForm .cancelEdit__btn,
.each__record .edit__btn:disabled,
.each__record .deactivate__btn:disabled {
	background-color: transparent !important;
}

.idDeetsForm button[type="submit"] {
	display: block;
	margin-left: auto;
	height: 50px;
	width: 312px;
	border: none;
	border-radius: 4px;
	color: #fff;
}

.records {
	padding-bottom: 30px;
}

.records select[aria-label="Rows per page:"] {
	width: min-content;
	margin: 0px;
	padding: 0px 20px;
}

.each__record {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 35px;
}

.each__record > div:last-child {
	display: flex;
	align-items: center;
}

.mid__wrapper {
	max-width: 736px;
}

.anssid__form {
	max-width: 736px;
}

.anssid__form > button {
	display: block;
	margin-left: auto;
	width: 284px;
	height: 55px;
}

.anssid__form__search {
	display: flex;
}

.anssid__form__search > button {
	display: block;
	margin-left: auto;
	border: none;
	width: 100px;
	height: 50px;
	color: var(--light-grey-08);
	background: none;
	border-radius: 4px;
	border: 1px solid var(--primary-03);
}

.anssid__form__search input {
	width: 200px;
	margin: 0px;
	margin-right: 10px;
}

.table {
	width: 100%;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
}

.table td:last-child {
	text-align: right;
}

.table tbody button {
	display: block;
	background-color: transparent;
	border: none;
	line-height: 26px;
	font-size: 16px;
	margin-left: auto;
}

.table tbody button:hover {
	box-shadow: none;
}

.table tbody button.deactive__btn {
	color: var(--primary-07);
	margin-bottom: 6px;
}

.table tbody button.delete__btn {
	color: var(--alerts-red);
	margin-top: 10px;
}

@media (max-width: 768px) {
	.settings__page {
		padding: 0px !important;
		margin-top: 32px;
	}

	.card {
		padding: 0 16px;
	}

	.anssid__form {
		width: 100vw;
		padding: 0 16px;
	}

	.idDeetsForm > .input__container,
	.inputs__div {
		flex-direction: column;
	}

	.idDeetsForm > .input__container input,
	.idDeetsForm > .input__container select,
	.inputs__div input,
	.inputs__div select {
		width: 100%;
	}

	.input__container > div,
	.inputs__div > div {
		width: 100%;
	}

	.input__container > div:first-child,
	.input__container > div:last-child,
	.inputs__div > div:first-child,
	.inputs__div > div:last-child {
		margin: 0;
	}

	.input__container > div:first-child,
	.inputs__div > div:first-child {
		margin-bottom: 16px;
	}

	.idDeetsForm .addNew__btn,
	.idDeetsForm .cancelEdit__btn,
	.each__record .edit__btn,
	.each__record .deactivate__btn {
		padding: 0;
	}

	.anssid__form > div.last {
		margin-bottom: 0;
	}

	.inputs__div div.single__input__div {
		width: 100%;
	}

	.idDeetsForm button[type="submit"],
	.anssid__form > button {
		margin: 0;
		width: 100%;
	}

	.anssid__form > button {
		margin-bottom: 60px;
	}

	.table {
		padding: 16px;
	}

	.records > div > div > div {
		flex-direction: column;
		border: 1px solid red;
	}
}
