.signup_page {
	display: flex;
}

.auth__form__container {
	width: 350px;
	margin-left: calc(30% + 108px);
	display: flex;
	flex-direction: column;
	margin-top: 110px;
	color: var(--grey-08);
}

.auth__form__container > a {
	text-decoration: underline;
	color: var(--grey-06);
	font-size: 12px;
	font-weight: 500;
	position: absolute;
	top: 42px;
	right: 58px;
}

.auth__form__container > img {
	display: none;
}

.heading__text {
	font-weight: 600;
	font-size: 36px;
	line-height: 44px;
	color: var(--grey-10);
}

.sub__heading__text {
	font-size: 14px;
	line-height: 26px;
	margin-bottom: 28px;
	color: var(--grey-06);
}

.sub__heading__text a {
	color: var(--green-02);
}

.tabs {
	display: flex;
	max-width: 149px;
	margin-bottom: 24px;
}

.tabs button {
	height: 25px;
	font-size: 14px;
	font-weight: 400;
	color: var(--grey-05);
	padding: 0 4px 8px;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

.tabs button.active_tab {
	border-bottom: 2px solid var(--green-01);
	color: var(--grey-10);
}

.tabs button:hover {
	box-shadow: none;
}

.auth__form {
	display: flex;
	flex-direction: column;
}

.auth__form > input,
.auth__form button {
	width: 100%;
	margin-bottom: 16px;
}

.form .hr {
	height: 1px;
	width: 100%;
	margin-top: 12px;
	margin-bottom: 36px;
	background-color: var(--lights-01);
	border: none;
}

button.submit__button {
	margin-bottom: 40px;
}

@media (max-width: 768px) {
	.signup_page {
		display: block;
	}

	.auth__form__container {
		margin: 0px;
		width: 100%;
		padding: 48px 16px;
	}

	.auth__form__container > a {
		top: 20px;
		right: 16px;
	}

	.auth__form__container > img {
		display: block;
		height: 80px;
	}

	.heading__text {
		font-size: 30px;
		line-height: 29px;
		margin-bottom: 32px;
		text-align: center;
	}

	.sub__heading__text {
		font-size: 14px;
		line-height: 23px;
		text-align: center;
	}

	.tabs {
		justify-content: center;
		max-width: 100%;
	}

	.signup_btn {
		display: block;
		margin-top: 12px;
		width: 100%;
	}
}
