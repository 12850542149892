.table__wrapper {
	overflow-x: auto;
}

.loading {
	opacity: 0.4;
}

.table {
	width: 100%;
}

.table thead tr {
	background-color: var(--green-00);
	white-space: nowrap;
}

.table thead tr th {
	text-align: left;
}

.table thead th {
	color: var(--neutrals-black);
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
}

.table,
.table td,
.table th {
	border-collapse: collapse;
}

.table th,
.table td {
	padding: 15px 15px 15px 20px;
	width: fit-content;
}

.table tbody {
	background-color: var(--neutrals-white);
}

.table tbody .table__row {
	position: relative;
}

.table tbody td {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--neutrals-black);
	border-right: 1px solid var(--light-grey-00-0);
	border-bottom: 1px solid var(--light-grey-00-0);
}

.table tbody td.pending {
	color: #f99601;
	text-transform: capitalize;
	font-weight: 600;
}

.table tbody td.pending__approval {
	color: var(--green-01);
	text-transform: capitalize;
	font-weight: 600;
	white-space: nowrap;
}

.table tbody td.approved {
	color: var(--green-03);
	text-transform: capitalize;
	font-weight: 600;
}

.table tbody td.denied {
	color: var(--red-02);
	text-transform: capitalize;
	font-weight: 600;
}

.amountPaid__tooltip {
	position: absolute;
	top: 70%;
	left: 50%;
	display: flex;
	gap: 10px;
	flex-direction: column;
	border-radius: 8px;
	padding: 7px 10px 9px !important;
	font-size: 14px !important;
	background-color: #fff;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
	z-index: 1;
}

.amountPaid__tooltip button {
	background: none;
	border: 1.5px solid red;
	border-radius: 50%;
	margin-left: auto;
	padding: 0 7px;
	width: fit-content;
	font-size: 14px !important;
}

.amountPaid__tooltip button:hover {
	box-shadow: none;
}

.table__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	color: #020808;
	padding: 10px;
}

.pagination {
	display: flex;
	align-items: center;
}

.icon__button {
	border: 1px solid var(--grey-01);
	border-radius: 4px;
	height: 30px;
	padding: 6.5px 11px;
	background: none;
	outline: none;
	cursor: pointer;
	font-size: 12px !important;
	font-weight: 500;
	line-height: 16px !important;
	color: var(--grey-10);
	margin-left: 5px;
	margin-right: 5px;
}

.icon__button:hover {
	box-shadow: none;
}

.icon__button.active {
	background: var(--green-02);
	color: #fff;
}

.arrow__left {
	background: url(../../assets/arrowLeft.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
}

.arrow__right {
	background: url(../../assets/arrowRight.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
}

.edit__btn,
.view__btn,
.revoke__btn,
.h1__button,
.profile__btn,
.print__btn,
.removeFromPrint {
	background-color: transparent;
	border: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	font-size: 15px;
	margin-bottom: 0;
	margin-right: 7px;
	white-space: nowrap;
}

.edit__btn {
	color: var(--primary-05);
}

.view__btn {
	color: var(--primary-07);
}

.revoke__btn {
	color: var(--red-01);
}

.h1__button {
	color: var(--green-05);
}

.profile__btn {
	color: var(--green-02);
}

.print__btn {
	color: var(--green-09);
}

.removeFromPrint {
	color: #ff8c00;
}

.edit__btn:hover,
.view__btn:hover,
.revoke__btn:hover,
.h1__button:hover,
.profile__btn:hover,
.print__btn:hover,
.removeFromPrint:hover {
	box-shadow: none;
	text-decoration: underline;
}

.filed__status__row {
	color: var(--white);
	text-align: center;
	margin: 0px;
	padding: 5px;
}

.filed {
	background-color: var(--green-05);
}

.not__filed {
	background-color: var(--red-02);
}
