.calculatorSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 100px 20px;
}

.calculatorSection img {
	width: 100%;
}

.calcSearchSection {
	text-align: center;
	margin-top: 54px;
}

.calcSearchSection h2 {
	font-size: 24px;
}

.calcSearchSection p {
	max-width: 100%;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	color: #202020;
	margin-top: 30px;
}

.calculatorSection button {
	width: 100%;
	margin-top: 20px;
	font-size: 14px;
	border-radius: 4px;
	padding: 10px 30px;
	background-color: #34aa63;
	border: none;
	outline: none;
	cursor: pointer;
	color: rgba(255, 255, 255, 1);
}

.underlineGreen {
	border-bottom: 4px solid var(--green-03);
	padding-bottom: 5px;
}

/* Tablet styles */

@media (min-width: 768px) {
	.calculatorSection {
		padding: 60px 118px;
	}

	.calculatorSection img {
		width: 80%;
	}

	.calcSearchSection {
		padding: 20px 100px;
	}

	.calcSearchSection h2 {
		font-size: 24px;
	}

	.calcSearchSection p {
		font-size: 16px;
		line-height: 24px;
		text-align: start;
	}
}

@media (min-width: 1024px) {
	.calculatorSection {
		flex-direction: row-reverse;
		justify-content: space-between;
		padding: 60px 118px;
	}

	.calculatorSection img {
		width: 45%;
	}

	.calcSearchSection {
		width: 41.5%;
		text-align: start;
		padding: 0;
	}

	.calcSearchSection h2 {
		font-size: 28px;
	}

	.calcSearchSection p {
		font-size: 16px;
		line-height: 25.12px;
		margin-top: 45px;
		align-items: center;
	}

	.calculatorSection button {
		margin-top: 32px;
		font-size: 15px;
		padding: 12px 57px;
	}
}
