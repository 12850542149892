/* .wrapper {
	min-width: 876px;
	width: 60%;
	padding: 0px 32px 32px !important;
	margin: 40px 0px 0px 48px;
} */

.wrapper > form {
	padding-top: 40px;
}

.header {
	background-color: var(--neutrals-white);
	padding: 22px 24px;
}

.top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 36px;
}

.top > div > button:first-of-type {
	border: none;
	background-color: transparent;
	color: var(--green-01);
	font-size: 14px;
	line-height: 23px;
	font-weight: 500;
}

.top button:hover {
	box-shadow: none;
}

.heading__text {
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 29px;
	color: var(--light-grey-06);
	margin: 0px;
}

.bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user__count {
	font-size: 16px;
	line-height: 26px;
	margin: 0;
	color: var(--light-grey-06);
}

.drop__arrow {
	display: inline-block;
	width: 14px;
	height: 8px;
	background: url(../../assets/arrowDown.svg);
}

.table {
	width: 100%;
	border-collapse: collapse;
	font-style: normal;
	font-weight: normal;
}

.table tbody td {
	padding: 27px 24px 23px;
	border-bottom: 1px solid var(--lights-03);
}

.table tbody td p {
	font-size: 16px;
	line-height: 26px;
	color: var(--light-grey-08);
}

.table td:last-child {
	text-align: right;
}

.table tbody button {
	display: block;
	background-color: transparent;
	border: none;
	font-size: 14px;
	line-height: 23px;
	margin-left: auto;
}

.table tbody button:hover {
	box-shadow: none;
}

.table tbody button.deactive__btn {
	color: var(--green-05);
	margin-bottom: 6px;
}

.table tbody button.edit__btn {
	color: var(--red-01);
	margin-top: 10px;
}

.nav__link {
	display: flex;
	align-items: center;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	text-decoration: none;
	color: var(--primary-01);
}

.nav__link img {
	margin-right: 10.5px;
}

.filter {
	position: absolute;
	left: 40%;
	transform: translateX(-50%);
}

.filter__button {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	display: flex;
	color: #020808;
}

.filter__button::before {
	content: "";
	display: inline-block;
	margin-right: 8px;
	width: 28px;
	height: 28px;
	background: var(--primary-08);
	mask: url(../../assets/filter.svg);
	display: flex;
	align-items: center;
}

.filter__form {
	width: 16vw;
	min-width: 228px;
	height: max-content;
	background: #ffffff;
	box-shadow: 0px 4px 20px #f2f2f2;
	border-radius: 4px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	position: absolute;
}

.form__control > label {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: #020808;
}

.form__control > input {
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	border-radius: 4px;
	width: 100%;
}

.filter__form > footer {
	border-top: 1px solid rgba(2, 8, 8, 0.05);
	padding-top: 20px;
}

.submit__button {
	background: none;
	outline: none;
	cursor: pointer;
	border: 1px solid #e69e00 !important;
	text-decoration: none !important;
	box-sizing: border-box;
	border-radius: 4px;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 26px !important;
	color: #e69e00 !important;
	width: 100%;
	height: 50px;
}

.no__display {
	display: none;
}

@media (max-width: 768px) {
	.wrapper {
		width: calc(100vw + 16px);
		margin-left: -16px;
	}

	.wrapper > form {
		padding-left: 16px;
	}

	.header {
		box-shadow: 0px 4px 10px rgba(0, 69, 138, 0.2);
	}

	.table tbody tr {
		display: flex;
		flex-direction: column;
		margin-top: 16px;
		background: var(--neutrals-white);
	}

	.table tbody td {
		padding: 0;
		width: calc(100% - 32px);
		margin: auto;
	}

	.table tbody tr td:not(:first-child) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.table tbody tr td:first-child p:first-of-type {
		font-weight: 500;
		margin: 24px 0 0 0;
	}

	.table tbody tr td:first-child p:last-of-type {
		margin-top: 0;
	}

	.table td:last-child {
		text-align: unset;
		padding: 16px 0 24px 0;
	}

	.table tbody button {
		margin-left: 0;
		padding: 0;
	}

	.table tbody button.deactive__btn {
		margin-bottom: 0;
	}

	.table tbody button.edit__btn {
		margin-top: 0;
	}
}
