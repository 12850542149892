.wrapper {
	display: flex;
}

.auth__form__container {
	width: 350px;
	margin-left: calc(30% + 108px);
	display: flex;
	flex-direction: column;
	margin-top: 110px;
	color: var(--grey-08);
}

.auth__form__container > a {
	text-decoration: underline;
	color: var(--grey-06);
	font-size: 12px;
	font-weight: 500;
	position: absolute;
	top: 42px;
	right: 58px;
}

.auth__form__container > img {
	display: none;
}

.heading__text {
	font-weight: 600;
	font-size: 36px;
	line-height: 44px;
	color: var(--grey-10);
}

.sub__heading__text {
	font-size: 14px;
	line-height: 26px;
	margin-bottom: 28px;
	color: var(--grey-06);
}

.sub__heading__text a {
	color: var(--green-02);
}

.auth__form {
	display: flex;
	flex-direction: column;
}

.auth__form > input,
.auth__form button {
	width: 100%;
	margin-bottom: 16px;
}

.auth__form > input:first-of-type {
	margin-bottom: 5px;
}

.submit__button {
	margin-bottom: 20px;
}

.auth__form > a {
	color: var(--green-02);
	margin-bottom: 32px;
}

@media (max-width: 768px) {
	.wrapper {
		display: block;
	}

	.auth__form__container {
		margin: 0px;
		width: 100%;
		padding: 48px 16px;
	}

	.auth__form__container > a {
		top: 20px;
		right: 16px;
	}

	.auth__form__container > img {
		display: block;
		height: 80px;
	}

	.heading__text {
		font-size: 30px;
		line-height: 29px;
		margin-bottom: 32px;
		text-align: center;
	}

	.sub__heading__text {
		font-size: 14px;
		line-height: 23px;
		text-align: center;
	}

	.submit__button {
		width: 100%;
	}
}
