.wrapper {
	width: 90%;
	margin: auto;
	background: transparent;
	margin-bottom: 48px;
}

.profile__photo {
	border-radius: 8px;
	height: 150px;
	width: 120px;
	margin-bottom: 20px;
}

.details {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.detail {
	margin-bottom: 10px;
}

.detail span {
	display: block;
}

.detail__name {
	font-weight: 600;
	color: var(--green-02);
}

.detail__value {
	font-size: 13px;
	color: var(--grey-06);
}

.pronounced__text {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 39px;
	display: flex;
	align-items: center;
	color: var(--grey-09);
}

.special__link {
	color: var(--green-02);
	text-decoration: underline;
	font-size: 14px;
}

.prompt__text {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	text-align: center;
	color: var(--light-grey-07);
}

.btn__div {
	width: 600px;
	margin: auto;
	margin-top: 36px;
	margin-bottom: 40px;
	display: flex;
	justify-content: flex-end;
}

.btn__div button,
.modal__btn_div button {
	padding: 0 48px;
}

.btn__div button:first-of-type {
	margin-right: 36px;
	background: transparent;
}

.modal__btn_div {
	margin-top: 24px;
	display: flex;
	justify-content: center;
}

.modal__btn_div button:first-of-type {
	margin-right: 31px;
}

.denial__reason_form {
	display: flex;
	flex-direction: column;
}

.denial__reason_form button {
	margin-top: 36px;
}

.denial__reason_form textarea {
	margin-top: 10px;
	height: 70px !important;
}

@media (max-width: 768px) {
	.details {
		display: block;
	}
}
