.main {
	display: flex;
}

.ymradio_btn {
	width: 28%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: -10px;
}

.doc__area {
	margin-top: 30px;
	border: 1px solid #000;
}

.notice__btns {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	gap: 8px;
}

.peradio_btn {
	width: 57%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: -15px;
}

.label_input {
	display: flex;
	align-items: center;
}

.radio_label {
	margin-right: 10px;
}

.mov_radio {
	margin-top: -40px;
}

input[type="radio"] {
	margin-right: 10px;
}

.dropdown_wrapper {
	display: flex;
	justify-content: space-between;
}

.billtype {
	margin-right: 25px;
}

.RdAmInput {
	width: 60%;
	margin-left: 90px;
}

.RdAmInput > label {
	margin-top: -8px;
}

.add_btn {
	width: 50%;
	margin-left: 275px;
	margin-top: 10px;
}

.adjust_table {
	display: flex;
	align-items: center;
}

.bottom_btn {
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
}

.bottom_btn_right {
	margin-right: 30%;
}

.read_only {
	/* border: 2px solid red; */
	opacity: 0.5;
	/* Adjust the opacity to visually indicate the disabled state */
	pointer-events: none;
	/* Disable mouse events */
}

.calc_btn {
	width: 245px;
}

.undertable {
	width: 120%;
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	margin-bottom: 30px;
}

.print__btn {
	width: 100%;
	margin-top: 50px;
	margin-bottom: 50px;
	display: "flex";
	align-items: center;
	justify-content: center;
	border: none;
	padding: 20px;
	font-size: 50px;
}

.printBtns {
	display: flex;
	float: right;
	margin-bottom: 20px;
}

.printAll__Btn {
	width: 180px;
	margin-left: 50px;
}

.invoiceBtns {
	margin-top: 30px;
	width: 100%;
	padding-bottom: 30px;
	display: flex;
	justify-content: space-between;
}

.invoiceBtns > div {
	width: fit-content;
	display: flex;
}

.invoiceBtns button {
	margin-right: 13px;
	flex: 1;
}

/* Bill Modal Action Btns Start */
.review__btns {
	position: relative;
	display: flex;
	gap: 24px;
	justify-content: center;
	margin: 24px 0;
}

.review__btns button {
	width: 156px;
}
/* Bill Modal Action Btns End */

.add__newbtns {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	border-radius: 10px;
	margin-bottom: 2rem;
	padding: 25px;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
}

/* Approval/Rejection update start */
.cert__btns {
	margin-bottom: 24px;
	display: flex;
	gap: 16px;
}

.download__btn,
.request__btn,
.to__mail_btn {
	padding: 0px 10px;
}

.close__btn {
	background-color: rgb(210, 72, 72) !important;
}

.to__mail_btn {
	background-color: transparent;
	border: 1px solid #f99601;
	border-radius: 4px;
	color: #f99601;
}

.rejection__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 20px;
	background-color: #f2d4d4;
	height: 68px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.rejection__badge span {
	font-weight: 600;
	color: var(--grey-10);
}

.reject__reason_instruction {
	margin-top: 84px;
	margin-bottom: 20px;
	text-align: center;
	max-width: 474px;
}

.reject__reason_form textarea {
	margin-bottom: 24px;
}

.reject__reason_form button {
	display: block;
	margin: auto;
	height: 40px;
	width: 160px;
}

/* Approval/Rejection update end */

@media (max-width: 768px) {
	.dropdown_wrapper {
		width: 65%;
		display: block;
		justify-content: space-between;
	}

	.input {
		width: 100%;
	}

	.search__btn {
		display: block;
		width: 100%;
	}

	.details {
		flex-direction: column;
	}

	.details > div {
		margin-bottom: 15px;
		width: 45%;
	}

	.action__btns {
		flex-direction: column;
		gap: 15px;
	}

	.action__btns button {
		width: calc(100% - 16px);
	}

	.payeForm {
		flex-direction: column;
	}

	.payeForm > div > button {
		width: 100%;
		margin-top: 15px;
	}
}
