.wrapper {
	width: 90%;
	margin: auto;
	background: #ffffff;
	box-shadow: 0px 4px 20px #f2f2f2;
	margin-bottom: 48px;
	padding: 0px 40px 0 40px;
}

.stats {
	width: 100%;
	margin-bottom: 80px;
}

.top__stats {
	display: flex;
	justify-content: space-between;
	margin-bottom: 36px;
}

.top__stats > .stat {
	width: calc(50% - 18px);
	background-color: #fff;
	padding: 24px;
}

.stat p:first-of-type {
	font-weight: normal;
	font-size: 18px;
	line-height: 29px;
	color: var(--light-grey-07);
}

.stat > p:last-of-type {
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: var(--neutrals-black);
	display: flex;
	justify-content: space-between;
}

.stat > p:last-of-type span {
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
}

.chart__card {
	background-color: #fff;
	padding: 36px 24px;
	margin-bottom: 36px;
}

.charts__container {
	display: flex;
	flex-wrap: wrap;
	gap: 40px;
	padding-bottom: 40px;
}

.chart__card > p:first-child {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 29px;
	color: var(--light-grey-07);
	opacity: 0.7;
	margin-bottom: 0px;
}

.chart__card > p:nth-child(2) {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	margin: 0px;
	color: var(--neutrals-black);
}

.close > .charts__container {
	display: none;
}

@media (max-width: 768px) {
	.stats {
		width: calc(100% - 16px);
		margin-top: 0 !important;
	}

	.top__stats {
		flex-direction: column;
	}

	.top__stats > .stat {
		width: 100%;
		margin-bottom: 16px;
		padding: 12px;
	}

	.stat > p:first-of-type {
		font-size: 14px;
		line-height: 23px;
		margin: 0;
	}

	.stat > p:last-of-type {
		font-weight: bold;
		font-size: 18px;
		line-height: 29px;
		margin: 0;
	}

	.stat > p:last-of-type span {
		font-size: 14px;
		line-height: 23px;
	}
}
