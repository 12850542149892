.heroSection {
	width: 100%;
	background-image: url(../../assets/Home/FaqBgImg.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 400px;
}

.heroSection > div {
	padding: 120px 0 0 0;
}

.heroSection h1 {
	font-size: 48px;
	font-weight: 600;
	line-height: 58.09px;
	color: rgba(255, 255, 255, 1);
	text-align: center;
	padding: 0;
}

.heroSection h3 {
	font-size: 18px;
	font-weight: 400;
	line-height: 25.89px;
	color: rgba(255, 255, 255, 1);
}

.heroSection .desktop {
	display: block;
}

.heroSection .mobile {
	display: none;
}

.accordion {
	padding: 60px 80px;
}

.heading {
	font-size: 48px;
	font-weight: 600;
	line-height: 58.09px;
	color: rgba(255, 255, 255, 1);
}

.item h2 {
	font-size: 24px;
	font-weight: 800;
	line-height: 29.05px;
	letter-spacing: -0.008em;
	color: #195130;
}

.item p {
	font-size: 18px;
	font-weight: 400;
	line-height: 29.12px;
	color: #202020;
}

.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: rgba(255, 255, 255, 1);
	cursor: pointer;
}

.title h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	letter-spacing: -0.008em;
	margin-top: 32px;
}

.content {
	overflow: hidden;
	max-width: 800px;
	max-height: 0;
	transition: all 0.5s cubic-bezier(0, 1, 0, 1);
	color: rgba(255, 255, 255, 0.6);
	font-family: Inter;
	font-size: 20px;
	font-weight: 400;
	line-height: 29.12px;
	margin-top: 10px;
}

.content .optionTitle {
	font-weight: 800;
}

.content .optionSteps {
	margin-left: 30px;
}

.contentShow {
	height: auto;
	max-height: 9999px;
	transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.partition {
	border: 1px solid #ececec;
	margin-top: 32px;
}

.faqLink:hover {
	color: #ffff;
}

@media (max-width: 768px) {
	.heroSection {
		background-image: url(../../assets/Home/FaqBgMobileImage.svg);
		height: 320px;
		padding: 0;
	}

	.heroSection > div {
		text-align: center;
	}

	.heroSection h1 {
		font-size: 35px;
		color: #ffffff;
		margin-top: -10px;
		line-height: 48.09px;
	}

	.heroSection .desktop {
		display: none;
	}

	.heroSection .mobile {
		display: block;
	}

	.accordion {
		width: 100%;
		padding: 5%;
	}

	.heading {
		font-size: 24px;
	}
	.title h2 {
		font-size: 18px;
		margin-top: 24px;
	}
}
