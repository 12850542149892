.heroSection {
	background-image: url(../../assets/Home/aboutUsBgMobile.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 320px;
	padding: 183px 0 30px 40px;
	color: #fff;
}

.heroSection h1 {
	font-size: 30px;
}

.heroSection h3 {
	font-size: 18px;
	margin-top: 12px;
}

@media (min-width: 1024px) {
	.heroSection {
		height: 400px;
		padding: 208px 0 96px 118px;
		background-image: url(../../assets/Home/aboutUsBg.svg);
	}

	.heroSection h1 {
		font-size: 48px;
	}

	.heroSection h3 {
		font-size: 16px;
	}
}

/* heroSection Ended  */

/* personalTaxIncome section */
.VisionMission {
	padding: 64px 20px;
	background-color: #195130;
	display: flex;
	flex-direction: column;
}

.VisionMission h1 {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.5;
	color: #ffffff;
	margin-top: 32px;
}

.underlineGold {
	border-bottom: 4px solid #f78c01;
	padding-bottom: 8px;
}

.paragraphs {
	width: 100%;
	max-width: 600px;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #ececec;
}

.paragraphs p {
	margin-top: 20px;
}

@media (min-width: 768px) {
	.VisionMission {
		flex-direction: column;
		justify-content: space-between;
		padding: 80px 120px;
		gap: 20px;
	}

	.VisionMission img {
		width: 100%;
	}
	.VisionMission h1 {
		font-size: 28px;
	}

	.paragraphs {
		font-size: 16px;
	}

	.paragraphs p {
		margin-top: 20px;
		font-size: 20px;
	}
}

@media (min-width: 1024px) {
	.VisionMission {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 80px 120px;
	}

	.VisionMission img {
		width: 40%;
	}
	.VisionMission h1 {
		font-size: 30px;
	}

	.paragraphs {
		font-size: 18px;
	}

	.paragraphs p {
		font-size: 18px;
	}
}
/* VisionMission Ended */

/* coreValueSection */
.coreValueSection {
	display: flex;
	flex-direction: column;
	padding: 64px 20px;
}

.CoreValues {
	width: 100%;
	max-width: 600px;
	display: flex;
	flex-direction: column;
}

.values strong {
	display: flex;
	align-items: center;
	color: #195130;
	font-size: 18px;
	font-weight: 600;
}

.bullet {
	font-size: 30px;
	margin-bottom: 15px;
	margin-right: 5px;
}

.values p {
	font-size: 16px;
	font-weight: 400;
	margin-left: 15px;
	color: #202020;
}

.RIPE {
	display: flex;
	gap: 5px;
	font-size: 20px;
	font-weight: 600;
}

@media (min-width: 768px) {
	.coreValueSection {
		flex-direction: column;
		justify-content: space-between;
		padding: 126px 120px;
		text-align: left;
		gap: 20px;
	}

	.RIPE {
		width: 70px;
	}
}

@media (min-width: 1024px) {
	.coreValueSection {
		flex-direction: row-reverse;
		padding: 126px 120px;
	}

	.CoreValues {
		width: 552px;
	}

	.RIPE {
		width: 100px;
	}
}
/* coreValueSection ended */

/* Staff picture */
.management {
	padding: 20px 15px;
	text-align: center;
}

.management h2 {
	font-size: 24px;
	font-weight: 800;
	line-height: 1.2;
	letter-spacing: -0.03em;
	color: #0c0c0c;
	margin-top: -40px;
}

.executiveChairman {
	width: 100%;
	max-width: 300px;
	height: auto;
	margin-top: 24px;
	text-align: center;
}

.higherStaffs,
.staffs {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 20px;
	justify-items: center;
}

.higherStaffCard,
.staffCard {
	width: 100%;
	max-width: 200px;
	margin-top: 24px;
}

.higherStaffCard h3,
.staffCard h3 {
	font-size: 16px;
	font-weight: 600;
	line-height: 1.2;
	color: #000000;
}

.higherStaffCard p,
.staffCard p {
	font-size: 13px;
	font-weight: 400;
}

@media (min-width: 768px) {
	.management {
		padding: 40px 60px;
	}

	.management h2 {
		font-size: 28px;
	}

	.executiveChairman {
		max-width: 400px;
		margin-top: 40px;
	}

	.higherStaffs {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;
		margin-top: 40px;
	}

	.staffs {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 20px;
		margin-top: 40px;
	}

	.higherStaffCard,
	.staffCard {
		max-width: 250px;
	}
}

@media (min-width: 1024px) {
	.management {
		padding: 0 120px 90px;
	}

	.management h2 {
		font-size: 32px;
	}

	.executiveChairman {
		max-width: 460px;
		margin-top: 54px;
	}

	.higherStaffs {
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 15px;
		padding: 0 122px;
	}

	.staffs {
		grid-template-columns: repeat(5, 1fr);
		grid-column-gap: 15px;
	}
}
