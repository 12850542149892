:root {
	--primary-01: #00a3ff;
	--primary-02: #0094ff;
	--primary-03: #0086ff;
	--primary-05: #e69e00;
	--primary-06: #0065ca;
	--primary-07: #005cb8;
	--primary-08: #b37b00;
	--primary-09: #0c3032;
	--secondary-01: #f5f8ff;
	--secondary-02: #d9d9d9;
	--secondary-03: #4d3500;
	--secondary-06: #e69e00;
	--secondary-09: #011f5b;
	--secondary-10: #0d0d0d;
	--white: #ffffff;
	--lights-00: #f5fbff;
	--lights-01: #e6f6ff;
	--lights-03: #ccedff;
	--lights-04: #99daff;
	--light-grey-00: #d8e5ee;
	--light-grey-00-0: #edf3f7;
	--light-grey-01: #90b4ce;
	--light-grey-04: #698396;
	--light-grey-05: #5e7687;
	--light-grey-06: #556a7a;
	--light-grey-07: #4d606d;
	--light-grey-08: #455663;
	--light-grey-09: #c5c5c5;
	--neutrals-black: #000d1a;
	--neutrals-white: #fffffe;
	--off-white: #ecececb2;
	--alerts-red: #f0372e;
	--red-00: #fbd0d8;
	--red-01: #ef4565;
	--red-02: #d73e5b;
	--red-06: #8d293c;
	--yellow-01: #fbf9d0;
	--yellow-02: #f99601;
	--background: #fff7e6;
	--green-00: #d4f2e0;
	--green-01: #3abda5;
	--green-02: #34aa63;
	--green-03: #2f9959;
	--green-04: #2a8a50;
	--green-05: #267c48;
	--green-09: #195130;
	--green-10: #16492b;
	--grey-01: #ececec;
	--grey-02: #d8d8d8;
	--grey-05: #9e9e9e;
	--grey-06: #7e7e7e;
	--grey-08: #455663;
	--grey-09: #202020;
	--grey-10: #0c0c0c;
	--dark-blue-01: #00458a;
	--dark-blue-05: #002d5b;
	--dark-blue-07: #002549;
	--dark-blue-09: #001e3b;
	--dark-blue-10: #001b35;
	--diluted-primary-01: rgba(0, 163, 255, 0.2);
	--pending: #f99601;
}

* {
	box-sizing: border-box;
	scrollbar-color: var(--primary-07) transparent;
	scrollbar-width: thin;
}

*::-webkit-scrollbar {
	width: 7px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--light-grey-01);
	border-radius: 2.5px;
	height: 50px;
}

*::-webkit-scrollbar-track {
	background: var(--light-grey-00);
	border-bottom-right-radius: 16px;
}

body {
	margin: 0;
	font-family:
		"Inter",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

label {
	display: block;
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--grey-09);
	margin-bottom: 4px;
}

input,
select {
	border: 1px solid var(--grey-02);
	height: 47px;
	border-radius: 8px;
	padding: 13px 12px;
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	background: transparent;
}

textarea {
	border: 1px solid var(--grey-02);
	border-radius: 8px;
	padding: 13px 12px;
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	background: transparent;
}

select > option {
	color: var(--secondary-09);
}

input::placeholder {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--grey-05);
}

input:active,
input:focus,
select:active,
select:focus {
	outline: none;
	border: 1px solid var(--primary-06);
	color: var(--neutrals-black);
}

input:hover,
select:hover {
	box-shadow: 0px 4px 4px rgba(194, 214, 254, 0.2);
}

button {
	cursor: pointer;
	font-weight: 500 !important;
	font-size: 14px !important;
	line-height: 23px !important;
}

button:disabled,
input:disabled,
select:disabled {
	cursor: not-allowed !important;
}

button:hover {
	box-shadow: 0px 4px 8px rgba(181, 235, 237, 0.8);
}

button:focus,
button:active {
	outline: none;
}

button.primary__btn {
	background-color: var(--green-02) !important;
	color: var(--neutrals-white) !important;
	height: 47px;
	border-radius: 4px !important;
	border: none !important;
}

button.primary__btn:disabled {
	background: var(--light-grey-01) !important;
	color: var(--light-grey-00);
}

button.primary__btn:hover {
	background-color: var(--green-04);
	color: var(--neutrals-white);
}

button.secondary__btn {
	background-color: var(--neutrals-white);
	color: var(--green-02);
	height: 47px;
	border-radius: 4px;
	border: 1px solid var(--green-02);
}

button.secondary__btn:disabled {
	border-color: var(--light-grey-01) !important;
	color: var(--light-grey-01) !important;
}

button.secondary__btn:hover {
	border-color: var(--green-04);
	color: var(--green-04);
}

button.tertiary__btn {
	background-color: var(--neutrals-white);
	color: var(--red-02);
	height: 47px;
	border-radius: 4px;
	border: 1px solid var(--red-02);
}

button.tertiary__btn:disabled {
	border-color: var(--red-00) !important;
	color: var(--red-00) !important;
}

button.tertiary__btn:hover {
	border-color: var(--red-01);
	color: var(--red-01);
}

.flat__list {
	list-style: none;
	display: flex;
	padding: 0;
	margin: 25px 0 38px;
}

.flat__list > li {
	display: flex;
	align-items: center;
}

.flat__list > li:not(:last-child) {
	margin-right: 48px;
}

.no__display {
	display: none;
}

input[type="radio"] {
	width: 24px;
	height: 24px;
}

.required {
	color: var(--alerts-red);
}

.pending {
	color: var(--pending) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.pending__approval {
	color: var(--green-01) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.approved {
	color: var(--green-02) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.denied,
.cancelled,
.deleted {
	color: var(--red-02) !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.error {
	background: #feebea;
	position: absolute;
	width: 66.66%;
	height: 60px;
	top: 0;
	right: 0;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #f0372e;
	padding: 0px 80px 0px 60px;
	display: flex;
	align-items: center;
}

.error::before {
	content: "";
	margin-right: 20px;
	background: url(./assets/alert.svg);
	width: 28px;
	height: 28px;
}

.react-datepicker-popper[data-placement^="bottom"] {
	margin-top: -30px !important;
}

.link__button {
	padding: 0;
	cursor: pointer;
	display: inline;
	color: var(--green-02);
	background: none;
	border: none;
	outline: none;
	text-decoration: underline;
	box-shadow: none !important;
}

/* loo */
.modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	max-width: 430px;
	height: 100%;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
	height: 100%;
	overflow-y: auto;
}

.modal.right .modal-body {
	padding: 15px 15px 80px;
}

.modal.right.fade .modal-dialog {
	right: -320px;
	-webkit-transition:
		opacity 0.3s linear,
		right 0.3s ease-out;
	-moz-transition:
		opacity 0.3s linear,
		right 0.3s ease-out;
	-o-transition:
		opacity 0.3s linear,
		right 0.3s ease-out;
	transition:
		opacity 0.3s linear,
		right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
	right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
	border-radius: 0;
	border: none;
}

.modal-header {
	border-bottom-color: #eeeeee;
	background-color: #fafafa;
}

.fa-spinner,
.approveRejectModalBg {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 2rem;
}

.approveRejectModalBg {
	background: #001428a1;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.approveRejectFlagModal {
	width: 50%;
	background-color: #fff;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
	border-radius: 15px;
	padding: 2.5rem;
	font-size: 16px;
}
.approveRejectFlagModal div {
	display: flex;
	gap: 10px;
	align-items: center;
	margin: 1rem 0;
}

/* @media (max-width: 1439px) {
	html {
		font-size: 14px;
	}
	input,
	select {
		height: 40px;
		padding: 10px 21px;
	}
}

@media (min-width: 1440px) {
	html {
		font-size: 16.9px;
	}
}

@media (max-width: 600px) {
	.flat__list {
		flex-flow: wrap;
	}
	.flat__list > li:not(:last-child) {
		margin-bottom: 10px;
	} */
/* } */
