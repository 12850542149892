.heroSection {
	background-image: url(../../assets/Home/stepsToPayBillsMobile.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 370px;
	padding: 94px 20px;
}

.heroSection h1 {
	font-size: 30px;
	font-weight: 600;
	line-height: 38px;
	color: rgba(255, 255, 255, 1);
	text-align: center;
}

.consolidatedSteps {
	padding: 40px 20px;
	display: block;
}

.desktop {
	display: none;
}

.mobile {
	display: block;
}

.phase1 {
	border: 2px solid #ececec;
	width: 100%;
	padding: 20px 15px;
	border-radius: 8px;
	margin-bottom: 20px;
}

.phase1 h4 {
	font-size: 18px;
	font-weight: 700;
	line-height: 29.1px;
	color: #195130;
}

.phase1 p {
	font-size: 16px;
	font-weight: 400;
	line-height: 25.89px;
	margin-top: 10px;
	color: #202020;
}

.consolidatedSteps2 {
	padding: 30px 20px;
	display: block;
}

.consolidatedStepsCtn {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.consolidatedStepsCtn h3 {
	width: 306px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

@media (min-width: 1024px) {
	.heroSection {
		background-image: url(../../assets/Home/stepsToPayBills.svg);
		height: 400px;
		padding: 94px 388px;
	}

	.heroSection h1 {
		font-size: 48px;
		line-height: 58.09px;
	}

	.desktop {
		display: block;
	}

	.mobile {
		display: none;
	}

	.consolidatedSteps {
		padding: 100px 220px 80px 220px;
		display: flex;
		justify-content: space-between;
		gap: 16px;
	}

	.phase1 {
		width: 330px;
		padding: 20px 30px;
		margin-bottom: 0;
		text-align: center;
	}

	.phase2 {
		text-align: center;
	}

	.consolidatedSteps2 {
		padding: 30px 360px;
		display: flex;
		justify-content: space-between;
		gap: 24px;
		margin-bottom: 100px;
	}

	.consolidatedStepsCtn h3 {
		font-size: 18px;
		line-height: 29.1px;
		width: 459px;
		text-align: center;
	}
}
