.esbnSection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 100px 20px;
	background-color: #f9f9f9;
}

.esbnSection img {
	width: 100%;
}

.searchSection {
	width: 100%;
}

.searchSection h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 33.89px;
	letter-spacing: -0.008em;
	color: rgba(0, 0, 0, 1);
	text-align: center;
	margin-top: 65px;
}

.underlineGreen {
	border-bottom: 4px solid var(--green-03);
	padding-bottom: 8px;
}

.searchSection h3 {
	font-size: 16px;
	font-weight: 400;
	line-height: 29.12px;
	color: #202020;
	margin-top: 28px;
	text-align: center;
}

.radioBtns {
	width: 100%;
	display: flex;
	margin-top: 32px;
}

.radio {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
}

.radio label {
	margin-top: 10px;
	color: #202020;
}

input[type="radio"] {
	accent-color: var(--green-03);
}

.individualInput {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.individualInput input,
.individualInput input:focus {
	width: 100%;
	border: none;
	outline: none;
	box-shadow: none;
	border-bottom: 1.5px solid rgba(0, 0, 0, 1);
	border-radius: 0;
	background-color: #f9f9f9;
	margin-top: 30px;
}

.individualInput input::placeholder,
.corporateInput input::placeholder {
	font-size: 15px;
	color: rgba(158, 158, 158, 1);
}

.corporateInput input,
.corporateInput input:focus {
	width: 100%;
	border: none;
	outline: none;
	border-bottom: 1.5px solid rgba(0, 0, 0, 1);
	border-radius: 0;
	margin-top: 30px;
	background-color: #f9f9f9;
}

.searchBtn {
	width: 100%;
	margin-top: 52px;
	font-size: 15px;
	border-radius: 4px;
	padding: 12px 48px;
	background-color: #ccc;
	border: none;
	outline: none;
	cursor: pointer;
	color: rgba(255, 255, 255, 1);
}

.result {
	font-size: 18px;
	font-weight: 700;
	line-height: 29.1px;
	text-align: center;
	margin-top: 60px;
	margin-bottom: 40px;
}

@media (min-width: 768px) {
	.esbnSection {
		padding: 60px 118px;
		align-items: center;
		justify-content: center;
	}

	.esbnSection img {
		width: 80%;
	}

	.individualInput {
		padding: 0 90px;
	}

	.corporateInput {
		padding: 0 90px;
	}

	.searchBtn {
		width: 80%;
		margin-left: 80px;
	}

	.searchSection h2,
	.searchSection h3 {
		text-align: center;
	}

	.radio {
		justify-content: center;
	}
}

@media (min-width: 1024px) {
	.esbnSection {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 60px 118px;
	}

	.esbnSection img {
		width: 41.8%;
	}

	.searchSection {
		width: 45%;
	}

	.searchSection h2 {
		text-align: left;
		font-size: 28px;
	}

	.searchSection h3 {
		text-align: left;
		font-size: 18px;
	}

	.radioBtns {
		width: 287px;
		margin: 0;
		justify-content: space-between;
		align-items: center;
	}

	.radio {
		width: auto;
	}

	.individualInput {
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		margin: 0;
		padding: 0;
	}

	.corporateInput {
		padding: 0;
	}

	.individualInput input,
	.individualInput input:focus {
		width: 48%;
	}

	.searchBtn {
		max-width: 220px;
		float: right;
	}

	.result {
		margin-top: 140px;
	}
}
