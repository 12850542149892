.get__ecard_area {
	margin-left: auto;
	position: relative;
}

.get__ecard_area > button {
	display: block;
	margin-left: auto;
	padding: 0 40px;
}

.modes {
	position: absolute;
	right: 0;
	width: 200px;
	margin-left: auto;
	border-radius: 8px;
	box-shadow: 2px 8px 15px rgba(0, 0, 0, 0.18);
}

.modes button {
	display: block;
	background: none;
	border: none;
	height: 30px;
	width: 100%;
}

.modes button:hover {
	box-shadow: none;
	color: var(--green-02);
}

.salutation {
	color: var(--green-02);
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 10px;
	line-height: 40px;
}

.basic {
	color: var(--neutrals-black);
	font-size: 16px;
	line-height: 26px;
	font-weight: 500;
	margin-bottom: 48px;
}

.details {
	font-weight: 500;
	line-height: 26px;
	font-size: 16px;
	color: var(--secondary-09);
}

.details a {
	color: var(--green-02);
	text-decoration: underline;
}

@media (max-width: 768px) {
	.get__ecard_area {
		padding-right: 16px;
	}
}
