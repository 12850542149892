* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* General styles */
.title {
	text-align: center;
	color: #000000;
	font-weight: 600;
	font-size: 28px;
	line-height: 30px;
	padding: 0 47px;
	margin-top: 50px;
}

.underlineGreen {
	border-bottom: 4px solid var(--green-03);
	padding-bottom: 8px;
}

.subTitleDesktop {
	display: none;
}

.subTitleMobile {
	text-align: center;
	line-height: 25px;
	font-size: 16px;
	margin-top: 10px;
	color: #202020;
	padding: 20px;
}
.cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 20px;
	margin: 0 auto;
	padding: 20px;
}

.cardContents {
	background-color: #fff;
	border: 1px solid #ececec;
	border-radius: 8px;
	overflow: hidden;
	transition: transform 0.2s ease-in-out;
	flex: 1 1 calc(100% - 32px);
	max-width: 100%;
	padding: 24px;
}

.cardContents:hover .cardHeading {
	color: #f78c01;
}

.cardContents:hover .cardText {
	color: #fff;
}

.cardContents:hover {
	cursor: pointer;
	background-color: #195130;
	color: #fff;
	transform: scale(1.05);
}

.cardHeading {
	color: rgba(25, 81, 48, 1);
	font-size: 18px;
	font-weight: 600;
	margin-top: 32px;
	line-height: 1.6rem;
}

.cardText {
	height: auto;
	font-size: 16px;
	font-weight: 400;
	line-height: 23px;
	margin-top: 10px;
	margin-bottom: 57px;
	color: #202020;
}

.learnMore {
	color: #00a3ff;
	font-weight: 500;
	line-height: 22px;
	font-size: 14px;
	text-decoration: none;
	transition: background-color 0.3s ease;
}

.learnMore a {
	color: #00a3ff;
	margin-top: 500px;
}

.cardContents:hover .learnMore {
	color: #f78c01;
}

@media (max-width: 767px) {
	.cards {
		flex-direction: column;
		gap: 16px;
	}

	.cardContents {
		flex: 1 1 100%;
		max-width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.cards {
		flex-direction: row;
		justify-content: space-between;
		gap: 16px;
	}

	.cardContents {
		flex: 1 1 calc(50% - 16px);
		max-width: calc(50% - 16px);
	}
}

@media (min-width: 1024px) {
	.subTitleDesktop {
		display: block;
		text-align: center;
		font-size: 18px;
		margin-top: 28px;
		color: #202020;
	}

	.subTitleMobile {
		display: none;
	}

	.cards {
		padding: 57px 118px 64px;
		flex-direction: row;
		justify-content: space-between;
		gap: 20px;
	}

	.cardContents {
		flex: 1 1 calc(25% - 32px);
		max-width: calc(27% - 32px);
	}

	.cardText {
		height: 220px;
	}
}

/* tax category cards Ended */

.procedures {
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-items: center;
	gap: 10px;
	background-color: rgba(25, 81, 48, 1);
}

.procedureContent {
	max-width: 534px;
	color: #fff;
}

.procedureTitleDesktop,
.procedureTitleMobile {
	font-size: 2em;
	margin: 0;
	line-height: 1.2;
}

.underlineGold {
	border-bottom: 4px solid #f78c01;
	padding-bottom: 8px;
}

.procedureText {
	font-size: 1em;
	line-height: 1.5;
}

.procedureText {
	font-size: 14px;
	font-weight: 400;
	line-height: 22.65px;
	color: rgba(255, 255, 255, 1);
}

.procedureContent {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.procedureContent button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: #f78c01;
	color: #000000;
	padding: 12px 48px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;
}

.procedureContent button img {
	margin-left: 10px;
}

@media (max-width: 767px) {
	.procedures {
		flex-direction: column;
		padding: 80px 20px;
		margin-top: 50px;
	}

	.procedures img {
		max-width: 100%;
		height: auto;
		flex: 1 1 100%;
	}

	.procedureTitleDesktop {
		display: none;
	}

	.procedureTitleMobile {
		display: block;
		text-align: center;
		font-size: 24px;
	}

	.procedureContent {
		margin-top: 50px;
	}

	.procedureText {
		text-align: center;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.procedures {
		flex-direction: column;
		padding: 60px 118px;
	}

	.procedureTitleDesktop {
		display: block;
		font-size: 25px;
	}

	.procedureTitleMobile {
		display: none;
	}

	.procedureContent {
		margin-top: 50px;
	}
}

@media (min-width: 1024px) {
	.procedures {
		flex-direction: row;
		padding: 60px 118px;
		gap: 122px;
	}

	.procedureTitleDesktop {
		display: block;
		font-size: 25px;
	}

	.procedureTitleMobile {
		display: none;
	}

	.procedureContent button {
		width: 280px;
	}
}

/* registerProcess */
.registerProcess {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 100px 20px;
}

.registerProcess img {
	width: 100%;
}

.process img {
	width: 100%;
}

.process h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 25.89px;
	color: #000;
}

.process h3 {
	font-size: 18px;
	font-weight: 400;
	line-height: 25.89px;
	color: #202020;
	margin-top: 24px;
	text-align: center;
}

.processSteps {
	margin-top: 20px;
	max-width: 630px;
}

.process button {
	width: 100%;
	padding: 12px;
	border-radius: 4px;
	font-size: 1rem;
	border: 1px solid rgba(47, 153, 89, 1);
	color: rgba(52, 170, 99, 1);
	background-color: #fff;
	text-decoration: none;
	margin-top: 30px;
	cursor: pointer;
}

@media (min-width: 768px) {
	.registerProcess {
		flex-direction: column;
		align-items: center;
		padding: 60px 118px;
	}

	.registerProcess img {
		width: 80%;
	}

	.process {
		width: 80%;
		margin-top: 0;
	}

	.process h1 {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.process h3 {
		width: 100%;
		font-size: 1.25rem;
		line-height: 1.75rem;
		margin-top: 25px;
	}

	.processSteps {
		margin-top: 30px;
		max-width: 1100px;
	}

	.process button {
		margin-top: 40px;
		max-width: 490px;
	}
}

@media (min-width: 1024px) {
	.registerProcess {
		flex-direction: row-reverse;
		padding: 102px 118px;
	}

	.registerProcess img {
		width: 45%;
	}

	.process {
		width: 41.7%;
	}

	.process img {
		width: 100%;
	}
	.baldMan {
		width: 80%;
	}

	.process h1 {
		font-size: 28px;
		line-height: 33.89px;
		margin-top: 0;
	}

	.process h3 {
		font-size: 18px;
		line-height: 29.12px;
		text-align: left;
	}

	.process button {
		max-width: 490px;
		margin-top: 50px;
	}
}
/* registerProcess Ending */
