.back__btn {
	margin-bottom: 27px;
	background: none;
	border: none;
	padding: 0;
}

.back__btn:hover {
	box-shadow: none;
}

.waiver__div {
	display: flex;
	gap: 80px;
	margin-bottom: 40px;
}

.waiver__div .checkbox {
	display: flex;
	align-items: center;
	gap: 10px;
}

.breakdown__btn {
	width: calc(50% - 18px);
}

.print__btn {
	display: block;
	padding: 0 20px;
	margin-bottom: 40px;
	margin-top: 20px;
}

.submit__btn {
	width: 30vw;
}

.manual__entry_table {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	margin-bottom: 40px;
}

.manual__entry_table > div > div:not(:first-of-type) {
	border-right: 1px solid var(--light-grey-01);
	border-bottom: 1px solid var(--light-grey-01);
	height: 75px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	padding-right: 10px;
	background-color: var(--neutrals-white);
}

.manual__entry_table > div > div.coloured {
	color: var(--neutrals-black);
	font-weight: 600;
	font-size: 14px;
	background: transparent;
	border-left: 1px solid var(--light-grey-01);
}

.manual__entry_table > div > div:first-of-type {
	color: var(--neutrals-black);
	font-weight: 600;
	font-size: 14px;
	background-color: var(--green-00);
	height: 45px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	border-right: 1px solid var(--light-grey-01);
	border-top: 1px solid var(--light-grey-01);
	border-bottom: 1px solid var(--light-grey-01);
	border-bottom: 1px solid var(--light-grey-01);
}

.manual__entry_table > div > div:first-of-type.uncoloured {
	background-color: var(--neutrals-white);
	border-left: 1px solid var(--light-grey-01);
}

.table__wrapper {
	overflow-x: auto;
}

.gender__picker {
	width: 100%;
	max-width: 400px;
	margin-bottom: 20px;
}

.gender__picker select {
	width: 100%;
}

.save__btn {
	width: 20%;
	float: right;
	margin-bottom: 40px;
}

.cert__btns {
	margin-bottom: 24px;
	display: flex;
	gap: 16px;
}

.download__btn,
.request__btn,
.to__mail_btn {
	padding: 0px 10px;
}

.to__mail_btn {
	background-color: transparent;
	border: 1px solid #f99601;
	border-radius: 4px;
	color: #f99601;
}

.review__instructions {
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	color: var(--red-02);
	text-align: center;
	max-width: 398px;
	margin: 12px auto;
}

.review__btns {
	position: relative;
	display: flex;
	gap: 24px;
	justify-content: center;
	margin: 24px 0;
}

.review__btns button {
	width: 156px;
}

.idiotProof__modal {
	position: absolute;
	bottom: 70px;
	background-color: #fff;
	box-shadow: 0px -2px 15px 0px #00254933;
	padding: 24px;
	max-width: 339px;
	border-radius: 8px;
	z-index: 3;
}

.idiotProof__modal p {
	text-align: center;
}

.idiotProof__btns {
	display: flex;
	gap: 16px;
	justify-content: center;
	margin-top: 16px;
}

.idiotProof__btns button {
	width: 80px;
	height: 31px;
	border-radius: 4px;
}

.idiotProof__btns button:first-of-type {
	border: 1px solid var(--grey-02);
	background: transparent;
}

.idiotProof__btns button:first-of-type:hover {
	box-shadow: 0px 2px 10px 0px #00254933;
}

.approve__modal p span {
	color: var(--green-02);
	font-weight: 600;
}

.approve__modal .idiotProof__btns button:last-of-type {
	background-color: transparent;
	color: var(--green-02);
	border: 1px solid var(--green-02);
}

.reject__modal p span {
	color: var(--red-02);
	font-weight: 600;
}

.reject__modal .idiotProof__btns button:last-of-type {
	background-color: var(--red-02);
	color: #fff;
	border: none;
}

.rejection__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 20px;
	background-color: #f2d4d4;
	height: 68px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.rejection__badge span {
	font-weight: 600;
	color: var(--grey-10);
}

.reject__reason_instruction {
	margin-top: 84px;
	margin-bottom: 20px;
	text-align: center;
	max-width: 474px;
}

.reject__reason_form textarea {
	margin-bottom: 24px;
}

.reject__reason_form button {
	display: block;
	margin: auto;
	height: 40px;
	width: 160px;
}

.approvalSuccess__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 20px;
	background-color: #d4f2e0;
	height: 66px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.approve__success_message {
	max-width: 342px;
	margin-top: 60px;
	margin-bottom: 20px;
	text-align: center;
	line-height: 24px;
	color: #000;
}

.continue__btn {
	display: block;
	height: 40px;
	width: 160px;
	margin: auto;
	border: none;
	border-radius: 4px;
	background-color: var(--green-02);
	color: #fff;
}

@media (max-width: 768px) {
	.waiver__div {
		flex-direction: column;
		gap: 5px;
	}

	.breakdown__btn {
		width: 100%;
	}

	.submit__btn {
		width: calc(100% - 16px);
	}

	.review__btns button {
		width: 100%;
	}
}
