.back__btn {
	margin-bottom: 27px;
	background: none;
	border: none;
	width: fit-content;
	padding: 0;
	color: var(--green-02);
	text-decoration: underline;
}

.back__btn:hover {
	box-shadow: none;
}
