.table__div {
	width: 100%;
	overflow-x: auto;
}

.actions__div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 52px;
}

.actions__div .filter__button,
.actions__div>button:first-of-type,
.actions__div a {
	background-color: transparent;
	color: var(--green-01);
	font-size: 14px;
	line-height: 23px;
	font-weight: 500;
}

/* HNI Radio Buttons */
.radio__button_div>div {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 15px;
}

.radio__button_div>div>div {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -5px;
}

.radio__button_div>div>div input {
	margin-right: 10px;
}

.radio__button_div>div>div label {
	margin: 7px 0px 0px 0px;
}

.radio__button_div>div>div:first-child {
	margin-right: 30px;
}

/* HNI Radio Buttons */

.actions__div>button:first-of-type {
	height: 47px;
}

.actions__div a {
	text-decoration: underline;
}

.actions__div button:hover,
.actions__div a:hover {
	box-shadow: none;
}

.filter {
	position: relative;
}

.filter__button {
	font-style: normal;
	display: flex;
	border: none;
}

.filter__button::before {
	content: "";
	display: inline-block;
	margin-right: 8px;
	width: 28px;
	height: 28px;
	background: var(--green-01);
	mask: url(../../assets/filter.svg);
	display: flex;
	align-items: center;
}

.clear__filter_fields {
	display: block;
	margin: auto;
	border: none;
	background-color: transparent;
	color: var(--green-01);
}

.clear__filter_fields:disabled {
	color: var(--light-grey-08);
	opacity: 0.2;
}

.filter__div {
	/* height: max-content; */
	max-height: calc(100vh - 200px);
	overflow-y: auto;
	width: 436px;
	background: var(--neutrals-white);
	box-shadow: 0px 6px 15px rgba(0, 37, 73, 0.12);
	padding: 36px;
	position: absolute;
	z-index: 1;
	right: 0px;
}

.filter__div .filter__header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 22px;
}

.filter__header h6 {
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: #000;
	margin: 0;
}

.filter__header button {
	font-size: 13.5px;
	color: var(--light-grey-06);
	border: none;
	background-color: transparent;
}

.filter__div>p {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--primary-07);
	margin-bottom: 16px;
}

.filter__div label {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-08);
	margin-bottom: 8px;
}

.filter__div form {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--light-grey-00);
	margin-bottom: 36px;
}

.filter__div form.radio__form {
	border-bottom: none;
	justify-content: flex-start;
	margin-bottom: 36px;
}

.filter__div form.radio__form div {
	display: flex;
	align-items: center;
	margin-right: 36px;
}

.filter__div form.radio__form div label {
	margin-bottom: 0;
}

.filter__div form.radio__form input {
	margin-right: 8px;
}

.filter__div form input:not([type="radio"]),
.filter__div form select {
	width: 164px;
	margin-bottom: 36px;
}

.form__control>input,
.form__control>select {
	border: 1px solid #d9d9d9;
	box-sizing: border-box;
	border-radius: 4px;
	width: 100%;
}

.filter__form>footer {
	border-top: 1px solid rgba(2, 8, 8, 0.05);
	padding-top: 20px;
}

.submit__button {
	outline: none;
	border: none;
	width: 100%;
}

.download__button {
	width: 200px;
	height: 47px !important;
}

.no__display {
	display: none;
}

.options__form {
	display: flex;
	justify-content: space-evenly;
	width: 50%;
}

.options__form input,
.options__form select {
	width: 260px;
	margin-bottom: 0px;
}

.revoke__warning {
	margin-top: 20px;
}

.revoke__btns {
	margin-top: 30px;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}

.revoke__btns button {
	padding: 14px 20px;
}

.revoke__btns button:last-of-type {
	background-color: var(--red-01);
	border: none;
	color: var(--neutrals-white);
	border-radius: 4px;
}

@media (max-width: 768px) {
	.actions__div {
		padding-right: 16px;
	}

	.actions__div>* {
		margin-bottom: 10px;
	}

	.filter__div {
		width: 92vw;
		padding: 16px 8px;
	}

	.download__button {
		display: none;
	}

	/* HNI Radio Buttons */
	.radio__button_div>div {
		justify-content: flex-start;
	}
}