.select {
	width: 250px;
}
.triple > div {
	margin-right: 10px;
	padding: 0; /* Keeps padding at 0 */
}
.triple {
	display: flex; /* Keeps Flexbox enabled */
	justify-content: flex-start; /* Aligns items to the start, giving you control over spacing */
	align-items: center; /* Keeps child elements vertically centered */
}

.inputs {
	width: 200px;
	margin-bottom: 32px;
}
.inputs1 {
	width: 335px;
	margin-bottom: 32px;
}
.buttonContainer {
	margin-top: 20px;
	width: 112px;
}
.buttonContainer3 {
	margin-top: 20px;
	width: 100px;
	margin-right: 1000px;
	margin-bottom: 10px;
}
.section {
	justify-items: flex-start;
}

.pad {
	margin-top: 16px;
}
.form {
	margin-top: 16px;
}

p,
b {
	font-weight: 500;
	line-height: 22.7px;
	margin-bottom: 6px;
}
/* section {
	margin-top: 32px;
}  */

.receipt__row {
	margin-bottom: 32px;
}
.buttonContainer2 {
	margin-top: 20px;
	width: 100px;
	margin-left: 400px;
	margin-bottom: 10px;
}
.modalopen {
	height: 100vh;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	justify-content: center;
}
.modaldesign {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	position: fixed;
	top: 50%;
	left: 50%;
	padding: 50px 20px;
	z-index: 100;
}
.button_container {
	display: flex;
	justify-content: flex-end;
	gap: 10px; /* Adjust the gap as needed */
	margin-bottom: 20px;
}
.button_container1 {
	justify-content: center;
	margin-left: 590px;
	margin-bottom: 20px;
}
.save_btn {
	background-color: transparent;
	width: 150px;
	border-radius: 10px;
}
.print_btn {
	width: 150px;
	border-radius: 15px;
}
.layout {
	border: 0;
	margin: auto;
	padding: 0;
}

/* Media query for width 700px or less */
@media (max-width: 768px) {
	.layout {
		padding: 0 16px;
	}
	.triple {
		display: block;
	}
	.buttonContainer {
		margin-top: 20px;
		width: 100%;
		margin-bottom: 10px;
	}
	.inputs,
	.inputs1 {
		width: 100%;
	}
}
