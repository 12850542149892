.PAYEintro {
	font-size: 14px;
	color: #202020;
	font-weight: 400;
	line-height: 22.65px;
}

.header {
	font-size: 16px;
	font-weight: 400;
	line-height: 25.89px;
}

.radioBtns {
	width: 65%;
	display: flex;
	margin-top: 16px;
}

.radioBtn {
	display: flex;
	align-self: center;
	justify-items: center;
}

.radioBtn label {
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	margin-top: 4px;
	color: #202020;
}

.radioBtns .radioBtn:nth-child(n + 2) {
	margin-left: 24px;
}

input[type="radio"] {
	accent-color: var(--green-03);
}

.fillingForm {
	margin-top: 40px;
	width: 65%;
}

.fillingForm button {
	width: 237px;
	margin-left: auto;
}

/* ////////////////////////////////////////////// */
.backText {
	color: var(--green-02);
	font-size: 14px;
	font-weight: 500;
	margin-left: 8px;
}

.backText:hover {
	text-decoration: underline;
}

.Dpage {
	background-color: #ffffff;
	padding: 24px;
	margin-top: 40px;
	height: auto;
}

.Dpage h3 {
	margin: 0;
}

.assessmentType {
	color: var(--green-02);
	font-size: 14px;
	font-weight: 600;
}

.instruction1 {
	padding: 12px 30px;
	background-color: #fbf9d0;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 20px;
}

.Dpage ul {
	margin: 0;
	padding: 0;
}

.esbnDetals {
	display: flex;
	justify-content: space-between;
	padding: 12px 24px;
	color: #202020;
	margin-bottom: 20px;
}

.columns h4,
.columns p {
	margin: 0;
}

.esbnDetals h4 {
	font-size: 12px;
	font-weight: 600;
	line-height: 14.52px;
}
.esbnDetals p {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.94px;
}

.columns {
	display: flex;
	flex-direction: column;
	gap: 24px;
	color: #000000;
}
.columns h4 {
	font-size: 12px;
	line-height: 14.52px;
	font-weight: 600;
	margin-bottom: 6px;
}

.columns p {
	font-size: 14px;
	font-weight: 400;
	line-height: 16.94px;
}

.downloadUploadSection {
	display: flex;
	justify-content: space-between;
	padding: 24px;
	background-color: #ececec;
	margin-bottom: 20px;
}

.downloadUploadSection h3 {
	color: #000000;
	font-size: 13px;
	font-weight: 500;
	line-height: 16.2px;
	margin: 0;
	text-transform: uppercase;
}

.downloadUploadSection button {
	margin-top: 8px;
}

.uploadArea input {
	margin-top: 8px;
}

.uploadArea button {
	max-width: 111px;
}

.uploadArea span {
	margin-left: 16px;
}
.instruction2 {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	padding: 16px 20px;
	background-color: #ccedff;
	text-transform: uppercase;
	margin-bottom: 20px;
}

.instruction2 ul li {
	text-decoration: none;
	list-style: none;
}

.statusCheck {
	display: flex;
	align-items: center;
	justify-content: center;
}
.statusCheck img {
	width: 18px;
	height: 18px;
}

.statusCheck p {
	font-size: 12px;
	font-weight: 400;
	line-height: 24px;
	margin-left: 5px;
	margin-top: 5px;
	color: #202020;
}

.employeeReviewHeader {
	color: #00458a;
	font-size: 16px;
	font-weight: 600;
	line-height: 25.9px;
	margin-top: 24px;
	margin-bottom: 16px;
	text-transform: uppercase;
}

.employeeDataBtn {
	width: 253px;
	display: flex;
	justify-content: space-between;
	gap: 24px;
	float: right;
	margin-top: 5px;
	margin-bottom: 24px;
}

.exitBtn {
	color: var(--green-02);
	padding: 12px 32px;
	border-radius: 4px;
	border: 1px solid var(--green-02);
	background-color: #ffffff;
}

.approvalSuccess__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	background-color: #d4f2e0;
	height: 66px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.HTMLcloseBtn {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	background-color: transparent;
	height: 66px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.successSection {
	display: flex;
	align-items: center;
}

.successSection h3 {
	color: #0c0c0c;
	margin-left: 8px;
	font-size: 16px;
	font-weight: 600;
	line-height: 19.36px;
}

.success_message {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 16px;
	text-align: center;
	line-height: 24px;
	color: #000;
}

.modalContents {
	margin-top: 50px;
	padding: 0 24px;
}

.modalBtn {
	display: flex;
	align-items: center;
	justify-content: center;
}

.modalBtn button {
	margin-bottom: 24px;
}

.viewDownloadBtn {
	width: 160px;
	height: 40px;
}
.continue__btn {
	display: block;
	height: 40px;
	width: 160px;
	border: none;
	border-radius: 4px;
	background-color: var(--green-02);
	color: #fff;
}

.HTMLGeneratedBillModal {
	position: absolute;
	top: 0;
	left: 334px;
	margin-top: 10px;
}

.HTMLGeneratedBill {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.billButton {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	color: var(--primary-03);
	box-shadow: none;
}

@media (max-width: 768px) {
	.radioBtns {
		width: 100%;
		padding-right: 200px;
	}

	.fillingForm {
		width: 100%;
		padding-right: 20px;
	}
	.Dpage {
		width: 100%;
		margin-right: 10px;
	}

	.esbnDetals {
		display: block;
	}

	.downloadUploadSection {
		display: block;
	}

	.downloadUploadSection button {
		margin-bottom: 30px;
	}
	.uploadArea input {
		width: 100%;
	}
}
