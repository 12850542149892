.visible {
	display: flex;
}

.invisible {
	display: none;
}

.modalOverlay {
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	justify-content: center;
	align-items: center;
	z-index: 3;
	cursor: pointer;
}

.modalContent {
	position: absolute;
	top: 40px;
	left: 5vw;
	width: 90vw;
	max-width: 680px;
	height: calc(100vh - 80px);
	background-color: #fefefe;
	overflow-y: auto;
	z-index: 4;
}

.announcement__header {
	padding: 14px 16px 20px;
	background-color: var(--grey-10);
}

.close__btn {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	border: none;
	border-radius: 50%;
	padding: 0;
	height: 22px;
	width: 22px;
	margin-left: auto;
}

.close__btn:hover {
	box-shadow: none;
}

.close__btn img {
	height: inherit;
	width: inherit;
}

.announcement__description div:first-of-type h2 {
	font-size: 24px;
	line-height: 29.05px;
	font-weight: 700;
	text-align: center;
	color: #fa0919;
	margin: 0;
	margin-bottom: 7.15px;
}

.announcement__description div:first-of-type p {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	text-align: center;
	color: #fff;
	margin: 0;
}

.announcement__description div.deadline {
	display: none;
}

.announcement__body {
	padding-top: 32px;
}

.announcement__body h3 {
	font-size: 12px;
	font-weight: 700;
	line-height: 14.52px;
	text-align: center;
	color: #202020;
	margin: 0;
	margin-bottom: 24px;
}

.steps {
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 12px;
	padding: 0 14px;
	margin-bottom: 32px;
}

.steps li {
	list-style-type: none;
	border: 1px solid var(--grey-02);
	border-radius: 12px;
	height: 80px;
	padding: 0 16px;
	display: flex;
	align-items: center;
}

.steps li span:first-of-type {
	height: 36px;
	width: 36px;
	margin-right: 10px;
	border-radius: 50%;
	padding: 6.5px 12px;
	background-color: var(--green-05);
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	line-height: 22.7px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.steps li span:last-of-type {
	font-size: 12px;
	line-height: 18px;
	color: #202020;
	word-wrap: break-word;
}

.steps li span:last-of-type a {
	font-weight: 700;
	color: var(--green-02);
}

.steps li span:last-of-type b {
	font-weight: 700;
}

.announcement__body .deadline {
	border: 1.36px solid #000;
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 80%;
	max-width: 280px;
	margin: 0 auto 32px;
}

.announcement__body .deadline span:first-of-type {
	background-color: #fa0919;
	border-bottom: 1.36px solid #000;
	padding: 8.18px 0;
	font-size: 16.36px;
	font-weight: 700;
	line-height: 30px;
	color: #fff;
}

.announcement__body .deadline span:last-of-type {
	padding: 16.36px 0;
	font-size: 24px;
	font-weight: 900;
	line-height: 30px;
	color: #000;
}

.announcement__body p {
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	color: #fd0506;
	text-align: center;
	width: 80%;
	margin: auto;
	margin-bottom: 32px;
}

.announcement__footer .countdown__container {
	padding: 20px 12px;
	background-color: var(--green-05);
}

.countdown__container p {
	font-size: 12px;
	font-weight: 700;
	line-height: 14.52px;
	text-align: center;
	color: #fff;
	width: 80%;
	margin: 0 auto 12px;
}

.countdown {
	display: flex;
	justify-content: center;
	column-gap: 8.99px;
}

.countdown > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 3.75px;
	background-color: #fff;
	width: 59.93px;
	padding: 8.99px;
}

.countdown > div span:first-of-type {
	font-size: 7.49px;
	font-weight: 600;
	line-height: 9.07px;
	color: var(--grey-06);
}

.countdown > div span:last-of-type {
	font-size: 26.97px;
	font-weight: 700;
	line-height: 32.64px;
	color: var(--grey-10);
}

.contact__container {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
	background-color: var(--grey-10);
	padding: 16px 0;
}

.contact__container div:first-of-type {
	color: #fff;
	text-align: center;
}

.contact__container div:first-of-type b {
	display: block;
	font-size: 14px;
	font-weight: 700;
	line-height: 16.94px;
	text-transform: uppercase;
	margin-bottom: 12px;
}

.contact__container div:first-of-type address {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	margin-bottom: 0;
}

.contact__container div:first-of-type address a {
	font-size: 14px;
	line-height: 16.94px;
	color: #fff;
}

.contact__container div:first-of-type address a img {
	margin-right: 8px;
}

.contact__container div:last-of-type {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	column-gap: 8px;
}

.contact__container div:last-of-type span {
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	color: #fff;
}

.contact__container div:last-of-type span span {
	color: var(--green-00);
}

.contact__container div:last-of-type img {
	width: 40px;
	height: 40px;
}

@media (min-width: 768px) {
	.modalContent {
		max-width: 1261px;
	}

	.announcement__header {
		padding: 24px 40px 32px;
	}

	.close__btn {
		height: 24px;
		width: 24px;
		margin-bottom: 16px;
	}

	.announcement__description {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	.announcement__description div:first-of-type h2 {
		font-size: 32px;
		line-height: 32px;
		text-align: left;
		margin-bottom: 16px;
	}

	.announcement__description div:first-of-type p {
		font-size: 18px;
		line-height: 26px;
		font-weight: 600;
		text-align: left;
		max-width: 87%;
	}

	.announcement__description div.deadline {
		border: 1.36px solid #000;
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 80%;
		max-width: 200px;
	}

	.announcement__description .deadline span:first-of-type {
		background-color: #fa0919;
		padding: 6px 0;
		font-size: 12px;
		font-weight: 700;
		line-height: 14.52px;
		color: #fff;
	}

	.announcement__description .deadline span:last-of-type {
		padding: 12px 0;
		font-size: 18px;
		font-weight: 900;
		line-height: 22px;
		color: #000;
		background-color: #fff;
	}

	.announcement__body h3 {
		font-size: 16px;
		line-height: 19.36px;
	}

	.steps {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 20px;
		padding: 0 40px;
	}

	.steps li {
		width: 280px;
	}

	.announcement__body .deadline {
		display: none;
	}

	.announcement__body p {
		font-size: 14px;
	}

	.announcement__footer .countdown__container {
		padding: 32px 0;
	}

	.countdown__container p {
		font-size: 16px;
		line-height: 19.36px;
		font-weight: 600;
	}

	.countdown {
		column-gap: 12px;
	}

	.countdown > div {
		width: 80px;
		padding: 12px;
	}

	.countdown > div span:first-of-type {
		font-size: 10px;
		line-height: 12.1px;
	}

	.countdown > div span:last-of-type {
		font-size: 36px;
		line-height: 43.57px;
	}

	.contact__container {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 16px 40px;
	}

	.contact__container div:first-of-type {
		text-align: left;
	}

	.contact__container div:first-of-type b {
		line-height: 22.65px;
		margin-bottom: 4px;
	}

	.contact__container div:first-of-type address {
		flex-direction: row;
	}

	.contact__container div:first-of-type address a:first-of-type {
		padding-right: 8px;
		border-right: 1px solid var(--green-01);
	}

	.contact__container div:first-of-type address a:last-of-type {
		padding-left: 8px;
	}

	.contact__container div:last-of-type {
		flex-direction: row;
	}

	.contact__container div:last-of-type span {
		font-size: 16px;
		line-height: 20px;
		text-align: end;
	}

	.contact__container div:last-of-type img {
		width: 64px;
		height: 64px;
		margin-left: 5px;
	}
}
