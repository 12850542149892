.review__instructions {
	font-size: 14px;
	font-weight: 700;
	line-height: 18px;
	color: var(--red-02);
	text-align: center;
	max-width: 398px;
	margin: 12px auto;
}

.review__btns {
	position: relative;
	display: flex;
	column-gap: 24px;
	margin: 24px 0;
}

.review__btns > button {
	min-width: 156px;
	padding: 0 15px;
}

.idiotProof__modal {
	position: absolute;
	bottom: 70px;
	background-color: #fff;
	box-shadow: 0px -2px 15px 0px #00254933;
	padding: 24px;
	max-width: 339px;
	border-radius: 8px;
	z-index: 3;
}

.idiotProof__modal p {
	text-align: center;
}

.idiotProof__btns {
	display: flex;
	gap: 16px;
	justify-content: center;
	margin-top: 16px;
}

.idiotProof__btns button {
	width: 80px;
	height: 31px;
	border-radius: 4px;
}

.idiotProof__btns button:first-of-type {
	border: 1px solid var(--grey-02);
	background: transparent;
}

.idiotProof__btns button:first-of-type:hover {
	box-shadow: 0px 2px 10px 0px #00254933;
}

.approve__modal p span {
	color: var(--green-02);
	font-weight: 600;
}

.approve__modal .idiotProof__btns button:last-of-type {
	background-color: transparent;
	color: var(--green-02);
	border: 1px solid var(--green-02);
}

.approval__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 20px;
	background-color: var(--green-00);
	height: 68px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.approval__comment_form {
	margin-top: 64px;
	width: 350px;
}

.reject__modal p span {
	color: var(--red-02);
	font-weight: 600;
}

.reject__modal .idiotProof__btns button:last-of-type {
	background-color: var(--red-02);
	color: #fff;
	border: none;
}

.rejection__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 20px;
	background-color: #f2d4d4;
	height: 68px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.rejection__badge span,
.approval__badge span {
	font-weight: 600;
	color: var(--grey-10);
}

.reject__reason_instruction {
	margin-top: 84px;
	margin-bottom: 20px;
	text-align: center;
	max-width: 474px;
}

.reject__reason_form textarea,
.approval__comment_form textarea {
	margin-bottom: 24px;
}

.reject__reason_form button,
.approval__comment_form button {
	display: block;
	margin: auto;
	height: 40px;
	width: 160px;
}

@media (max-width: 768px) {
	.review__btns {
		flex-direction: column;
		padding-right: 16px;
		row-gap: 16px;
	}

	.review__btns button {
		width: 100%;
	}

	.approval__comment_form {
		width: 80vw;
	}
}
