.nav {
	position: fixed;
	overflow-y: auto;
	background-color: var(--dark-blue-07);
	color: var(--white);
	width: 297px;
	height: 100vh;
}

.nav::-webkit-scrollbar {
	display: none;
}

.nav:hover::-webkit-scrollbar {
	display: block;
}

.logo {
	height: 145px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 240px;
}

.mda__name {
	margin: 30px 0px 0px 0px;
}

.nav__list {
	margin: 71.24px 0px 0px;
	padding-left: 0px;
	list-style: none;
}

.nav__list > li > div:first-child {
	height: 56px;
}

.nav__list > li > div {
	margin: 24px 0px;
}

.nav__button {
	padding-left: 37px;
	padding-right: 39px;
	border: none;
	background: none;
	outline: none;
	width: 100%;
	height: 100%;
	text-align: left;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 46px;
	color: var(--lights-01);
	text-decoration: none !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav__button span {
	display: flex;
	align-items: center;
}

.nav__button span > img {
	margin-right: 10px;
}

.nav__button:hover {
	box-shadow: none;
	color: var(--lights-01);
}

.divider {
	margin: 0px 20px;
	margin-right: 39px;
	padding: 0px;
	height: 1px;
	border: none;
	background: var(--primary-01);
}

.active {
	background: var(--primary-07) !important;
	color: var(--neutrals-white) !important;
}

.nested__nav__list {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 16px;
	height: 100%;
	text-decoration: none !important;
	color: var(--white) !important;
	padding-left: 68px;
}

.active__submenu {
	color: var(--white);
}

.no__display,
.menu__button {
	display: none;
}

.mobile__top {
	display: none;
}

.background {
	display: none;
}

@media (max-width: 768px) {
	.nav {
		z-index: 2;
	}

	.logo {
		display: none;
	}

	.mobile__top {
		display: flex;
		justify-content: space-between;
		padding-top: 24px;
		padding-right: 28px;
	}

	.mobile__top > div {
		display: flex;
		padding-left: 23px;
		align-items: center;
	}

	.mobile__top > div img {
		height: 32px;
		width: 32px;
		margin-right: 8px;
	}

	.mobile__top > div > p {
		font-weight: 500;
		font-size: 10px;
		line-height: 16px;
		color: var(--lights-00);
	}

	.mobile__top > div > p span {
		font-weight: normal;
		color: var(--lights-04);
	}

	.mobile__top > button {
		background-color: transparent;
		border: none;
		color: var(--lights-04);
		font-size: 13.5px;
	}

	.background {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background-color: var(--dark-blue-10);
		opacity: 0.5;
		z-index: 1;
	}

	.menu__button {
		display: inline-block;
		background: url(../../assets/hamburger.svg);
		width: 50px;
		height: 50px;
		border: none;
		outline: none;
	}
	.menu__button:hover {
		box-shadow: none;
	}
	.menu__toggle {
		margin-left: 10px;
		/* height: fit-content; */
	}
	.menu__toggle > div:last-of-type {
		height: calc(100% - 50px);
	}
	.collapsed__menu {
		position: absolute;
	}
}
