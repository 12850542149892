.modal__body h3 {
	margin: 10px 0px 30px 0px !important;
}

.my_30 {
	margin: 30px 0px;
}

.modal__button__wrapper {
	margin: 0px auto;
	width: 50%;
}

.modal__button {
	width: 200px;
	background-color: #fff;
	border: 1px solid rgba(52, 170, 99, 1);
	color: rgba(52, 170, 99, 1);
	border-radius: 4px;
}

.sidebar__alert {
	/* position: fixed;
	top: 0;
	left: 5%;
	right: 5%; */
	margin: auto;
	width: fit-content;
	text-align: center;
	padding: 0 30px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 3;
	border-radius: 2px;
	font-size: 15px;
}

.success {
	color: #fff;
	background-color: seagreen;
}

.error {
	background-color: #feebea;
	color: var(--alerts-red);
}
