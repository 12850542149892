.page {
	padding-top: 100px;
	min-height: 100vh;
	background-color: var(--light-grey-00-0);
	padding-bottom: 60px;
}

.form {
	padding: 50px;
}

.btn {
	width: 100%;
}

.main {
	min-width: 250px;
	max-width: 780px;
	margin: auto;
	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0px 12px 20px 0px rgba(31, 48, 63, 0.1);
}

.go__back {
	background: none;
	border: none;
}

.go__back:hover {
	box-shadow: none;
}

.radio__btns {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-bottom: 20px;
}

.radio__btns > div {
	display: flex;
	align-items: center;
}

.radio__btns div label {
	margin: 0;
}

.adjust {
	padding-left: 40px;
}

.wrapper {
	padding: 10px 20px 40px 20px;
	box-shadow: 0px 12px 20px 0px rgba(31, 48, 63, 0.1);
}

.head {
	padding: 40px;
	background-color: var(--dark-blue-07);
}

.align {
	display: flex;
}

.interswitch__header {
	display: flex;
	align-items: center;
	color: white;
	height: 68px;
	padding-left: 16px;
	margin: 0px 0px 15px 0px;
	background-color: rgba(22, 71, 130, 1);
}

.interswitch__header > img {
	margin-left: auto;
	margin-right: auto;
}

.remita__header {
	display: flex;
	align-items: center;
	color: white;
	height: 68px;
	padding-left: 16px;
	margin: 0px 0px 15px 0px;
	background-color: rgb(163 71 7);
}

.remita__header > img {
	margin-left: auto;
	margin-right: auto;
}

.flutterwave__header {
	display: flex;
	align-items: center;
	color: white;
	height: 68px;
	padding-left: 16px;
	margin: 0px 0px 15px 0px;
	background-color: #1b1931;
}

.flutterwave__header > img {
	margin-left: auto;
	margin-right: auto;
}

.unifiedpayment__header {
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	margin: 15px 0px 15px 0px;
	background-color: rgb(255, 255, 255);
}

.Etransac__header {
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	margin: 15px 0px 15px 0px;
	background-color: rgb(36, 76, 150);
}
.Monie__point {
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	margin: 15px 0px 15px 0px;
	background-color: rgba(4, 112, 240, 0.8);
}

.interswitch__header > img,
.remita__header > img,
.flutterwave__header > img,
.unifiedpayment__header > img,
.Etransac__header > img,
.Monie__point > img {
	height: 40px;
	margin-left: auto;
	margin-right: auto;
}
.divider {
	width: 100%;
	height: 1px;
	background: rgb(0 0 0 / 4%);
	border: 0;
	margin: 22px 0px 10px 0;
}

.backdrop {
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
}

.home__btn {
	display: block;
	position: absolute;
	top: 40px;
	left: 40px;
	padding: 10px 20px;
}

.custom__alert {
	top: 200px;
	left: 30%;
	width: 40%;
	position: fixed;
	background-color: var(--white);
	border: 10px;
	padding: 40px 40px 40px 40px;
	box-shadow: 0px 25px 40px 0px rgba(31, 48, 63, 0.1);
}

.payments__double__inputs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 32px;
}

.esbn__selector {
	display: flex !important;
	justify-content: flex-start;
	align-items: center;
	margin-right: 20px;
}

.esbn__selector label {
	margin: 0px 0px 0px 5px;
	font-size: 12px;
}

.esbn__selector > input[type="radio"] {
	width: 18px;
	height: 19px;
	margin: 0;
	accent-color: rgb(18 167 139);
}

.single__input {
	margin-bottom: 32px;
}

.single__input > input {
	width: 100%;
}

.payments__double__inputs > div {
	width: calc(50% - 18px);
	display: flex;
	flex-direction: column;
}

.payments__double__inputs > div:first-of-type {
	margin-right: 36px;
}

.table {
	margin: 16px 0 48px;
	border-radius: 4px;
	background: #eceff2;
	padding: 16px;
	width: 100%;
}

.table:nth-of-type(2) {
	margin-top: 0;
}

table,
.table td,
.table th {
	border-collapse: collapse;
}

.table thead {
	color: var(--grey-10);
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 16px 16px 0;
}

.table thead th {
	padding: 20px 10px 10px 0;
	border-bottom: 1px solid #cfd3da;
}

.table td {
	padding: 10px 0;
	border-bottom: 1px solid #cfd3da;
}

.table thead th:first-of-type,
.table td:first-of-type {
	padding-left: 15px;
}

.table .no__border_bottom td {
	border-bottom: none;
}

.table td.discount {
	color: var(--grey-10);
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	padding-top: 22px;
}

.table tbody tr.no__border_bottom:nth-of-type(even) td.discount {
	padding-top: 10px;
}

.table td.total {
	color: #03931a;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	padding-top: 10px;
	padding-bottom: 26px;
}

.payment__history_header {
	margin: 0;
	margin-top: -48px;
	padding-top: 15px;
	font-size: 14px;
	font-weight: 600;
	color: var(--red-06);
	text-align: center;
	background: #eceff2;
}

.payment__history_header::after {
	content: "";
	display: block;
	width: 50%;
	height: 1px;
	background: #cfd3da;
	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 768px) {
	.page {
		padding: 0;
	}

	.payments__double__inputs > div {
		width: 100%;
		margin-right: 0;
	}

	.payments__double__inputs > div:first-of-type {
		margin-right: 0;
	}
}

@media (min-width: 1080px) {
	.custom__alert {
		width: 60%; /* Adjust the width for large screens */
	}
}

@media (min-width: 600px) {
	.custom__alert {
		width: 40%; /* Adjust the width for medium screens */
	}
}

@media (max-width: 400px) {
	.custom__alert {
		width: 20%;
		left: 40%; /* Adjust the width for smaller screens */
	}
	.success_payment {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 100px;
		font-size: 60px;
	}

	.img_payment {
		padding: 20px;
		height: 100px;
	}
}
