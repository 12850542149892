.wrapper {
	display: flex;
}

.faux__sidenav {
	width: 297px;
}

.main__right {
	width: calc(100vw - 297px);
	min-height: 100vh;
	overflow-x: hidden;
	background-color: var(--lights-00);
}

.main__right > *:nth-child(2) {
	padding: 51px 40px 0 40px;
}

@media (max-width: 768px) {
	.main__right {
		padding-top: 29px;
		width: 100%;
		overflow-x: auto;
	}

	.main__right > *:nth-child(2) {
		padding-left: 16px;
		padding-right: 0;
	}

	.faux__sidenav {
		display: none;
	}
}
