.heroSection {
	background-image: url(../../assets/Home/tax-typeMobileBg.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 375px;
	padding: 183px 20px 36px 20px;
	color: #fff;
}

.heroSection h1 {
	font-size: 30px;
}

.heroSection h3 {
	font-size: 15px;
	margin-top: 12px;
	line-height: 25px;
}

@media (min-width: 768px) {
	.heroSection {
		padding: 208px 0 96px 50px;
	}

	.heroSection h3 {
		font-size: 18px;
	}
}

@media (min-width: 1024px) {
	.heroSection {
		height: 400px;
		padding: 208px 0 96px 118px;
		background-image: url(../../assets/Home/tax-typeBg.svg);
	}

	.heroSection h1 {
		font-size: 48px;
	}

	.heroSection h3 {
		font-size: 16px;
	}
}

/* heroSection Ended  */

/* personalTaxIncome */
.personalTaxIncome {
	padding: 100px 20px 0 20px;
	display: flex;
	flex-direction: column;
}

.personalTaxIncome img {
	width: 100%;
	height: auto;
}

.personalTaxIncomeLeft {
	margin-top: 24px;
}

.paragraphs h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
	color: rgba(12, 12, 12, 1);
}

.mobile {
	display: block;
}

.desktop {
	display: none;
}

.paragraphs p,
.paragraphs h4 {
	font-size: 14px;
	font-weight: 400;
	line-height: 1.6;
	margin-top: 20px;
	color: #202020;
}

.paragraphs button {
	padding: 10px 20px;
	background-color: #34aa63;
	color: #fff;
	border: none;
	cursor: pointer;
	margin-top: 20px;
}

.table th {
	border: 2px solid #5b5b5b;
	padding: 8px 24px;
	width: 270px;
}

@media (min-width: 768px) {
	.personalTaxIncome {
		flex-direction: column;
		justify-content: space-between;
		padding: 40px 60px;
		text-align: left;
		gap: 20px;
	}

	.personalTaxIncome img {
		width: 100%;
		margin-top: 0;
	}

	.personalTaxIncomeLeft {
		margin-top: 30px;
	}

	.paragraphs h2 {
		font-size: 28px;
	}

	.mobile {
		display: none;
	}

	.desktop {
		display: block;
	}

	.paragraphs p,
	.paragraphs h4 {
		font-size: 20px;
	}

	.paragraphs button {
		margin-top: 10px;
	}
}

@media (min-width: 1024px) {
	.personalTaxIncome {
		flex-direction: row-reverse;
		padding: 3% 120px;
	}

	.personalTaxIncome img {
		width: 50%;
		max-width: 500px;
	}

	.personalTaxIncomeLeft {
		max-width: 600px;
	}

	.paragraphs h2 {
		font-size: 32px;
	}

	.paragraphs p,
	.paragraphs h4 {
		font-size: 18px;
	}
}

/* personalTaxIncome Ended */

/* PitContainer */
.PitContainer {
	padding: 53px 20px 0 20px;
}

.PitContainer h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
	color: rgba(12, 12, 12, 1);
}

.mobile {
	display: block;
}

.desktop {
	display: none;
}

.pitCards {
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 32px;
}

.pitCard {
	border: 1px solid rgba(236, 236, 236, 1);
	border-radius: 8px;
	padding: 16px;
}

.underlineGreen {
	border-bottom: 4px solid #2f9959;
	padding-bottom: 8px;
}

.pitCard h2 {
	font-size: 18px;
	width: 60px;
	height: 60px;
	padding: 20px;
	text-align: center;
	border-radius: 50%;
	font-weight: 600;
	background-color: rgba(212, 242, 224, 1);
}

.pitCard h3 {
	font-size: 18px;
	font-weight: 600;
	line-height: 1.4;
	color: rgba(25, 81, 48, 1);
	margin-bottom: 10px;
}

.pitCard p {
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
	color: #202020;
}

@media (min-width: 768px) {
	.PitContainer {
		padding: 40px 60px;
		text-align: left;
	}

	.PitContainer h2 {
		font-size: 28px;
	}

	.pitCards {
		flex-direction: row;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: space-between;
	}

	.mobile {
		display: none;
	}

	.desktop {
		display: block;
	}

	.pitCard {
		width: calc(50% - 10px);
		padding: 24px;
	}

	.pitCard h2 {
		font-size: 20px;
		width: 70px;
		height: 70px;
		padding: 25px;
	}

	.pitCard h3 {
		font-size: 20px;
	}

	.pitCard p {
		font-size: 15px;
	}
}

@media (min-width: 1024px) {
	.PitContainer {
		padding: 3% 120px;
	}

	.PitContainer h2 {
		font-size: 32px;
	}

	.pitCard h2 {
		font-size: 24px;
		width: 80px;
		height: 80px;
		padding: 30px;
	}

	.pitCard h3 {
		font-size: 18px;
	}

	.pitCard p {
		font-size: 16px;
	}
}

/* PitContainer Ended */

.underlineGreen {
	border-bottom: 4px solid #2f9959;
	padding-bottom: 8px;
}
