.breakdown__btn {
	width: calc(50% - 18px);
}

.table__area {
	display: flex;
	overflow-x: auto;
}

.correction__inputs {
	padding-top: 46px;
}

.correction__inputs form {
	display: flex;
	margin-top: 7px;
	margin-bottom: 14px;
}

.receiptNo__breakdown {
	display: flex;
	font-size: 13px;
	color: var(--green-02);
}

.receiptNo__breakdown span {
	padding: 0 5px;
}

.receiptNo__breakdown span:first-of-type {
	border-right: 1.5px solid #000;
}

.receiptNo__breakdown button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
}