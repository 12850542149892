.form {
	width: 100%;
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	margin-bottom: 0px;
}

.form > header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #ffe7b3;
}

.create__btn {
	height: 47px !important;
	width: 197px;
}

.submit__btn {
	float: right;
	height: 50px;
	width: 255.22px;
	border: none;
	border-radius: 4px;
	color: var(--white);
	font-size: 16px;
	background-color: var(--primary-05);
}

/* Authorize search */
.search {
	display: flex;
	justify-content: center;
	margin-bottom: 42px;
}

.search input {
	width: 40%;
	border: 1px solid var(--light-grey-00);
	border-radius: 4px 0px 0px 4px;
	background: transparent;
}

.search input::placeholder {
	color: var(--light-grey-02) !important;
	font-size: 14px !important;
}

.search select {
	width: 15%;
	background: transparent;
	border: 1px solid var(--light-grey-00);
	border-radius: 0;
}

.search button {
	width: 10%;
	border-radius: 0px 4px 4px 0px;
	background: var(--green-02);
	border: none;
	color: var(--white);
}

/* Anssid audit details */
.top {
	display: flex;
	justify-content: space-between;
	margin-bottom: 36px;
}

.top button:first-of-type {
	display: flex;
	padding-left: 0;
}

.top button img {
	margin-right: 7px;
}

.top button {
	border: none;
	border: none;
	color: var(--primary-01);
	background-color: transparent;
	cursor: pointer;
}

.top button:hover {
	box-shadow: none;
}

.header {
	font-weight: 600;
	font-size: 36px;
	line-height: 44px;
	color: var(--light-grey-08);
}

.details {
	display: flex;
}

.left {
	width: 70%;
}

.details__block {
	padding-bottom: 36px;
	border-bottom: 1px solid var(--lights-03);
	margin-bottom: 36px;
}
.details__block:last-of-type {
	border-bottom: none;
}

.details__block h2,
.right h2 {
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
	color: var(--primary-07);
}

.details__block div {
	display: flex;
	justify-content: space-between;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	color: var(--light-grey-08);
	margin-bottom: 16px;
}

.right {
	padding-left: 62px;
}

.biopic {
	height: 354px;
	width: 237px;
}

.mobile__print_btn {
	display: none;
}

/* Print */
.print__details {
	background-color: var(--white);
}

.print__details__header {
	display: flex;
	justify-content: space-between;
	background-color: var(--lights-01);
	padding: 30px;
	height: 100px;
}

.print__main {
	display: flex;
	padding: 36px 30px 36px 30px;
}

.print__main h2 {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: var(--primary-07);
	margin-bottom: 12px;
}

.print__left {
	flex: 1;
}

.main__top {
	display: flex;
	justify-content: space-between;
	margin-bottom: 36px;
}

.main__top > div > img {
	height: 150px;
	width: 150px;
}

.main__top > div div {
	font-size: 14px;
	line-height: 23px;
	color: var(--secondary-10);
}

.print__left .deets {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	line-height: 23px;
	margin-bottom: 8px;
	color: var(--light-grey-06);
}

.print__left .deets span {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-08);
}

.print__right img {
	filter: opacity(0.05);
}

@media (max-width: 768px) {
	.header {
		font-weight: 600;
		font-size: 18px;
		line-height: 29px;
		margin-bottom: 16px;
	}

	.top button:first-of-type span {
		display: none;
	}

	.top button:last-of-type {
		display: none;
	}

	.details {
		flex-direction: column-reverse;
	}

	.left {
		width: calc(100% - 16px);
	}

	.right {
		padding-left: 0;
		padding-bottom: 36px;
		margin-bottom: 36px;
		border-bottom: 1px solid var(--lights-03);
	}

	.biopic {
		width: 152px;
		height: 227px;
	}

	.mobile__print_btn {
		display: block;
		width: calc(100% - 16px);
		margin-bottom: 36px;
	}
}
