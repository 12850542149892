.header__btns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.header__btns .filter__button {
	display: flex;
	align-items: center;
	border: none;
	background: transparent;
	color: var(--green-01);
}

.review_header {
	font-weight: 900;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 50px;
	border: none;
	box-shadow: none;
	outline: none;
}

.review_header_btn {
	border: none;
	color: var(--primary-01);
	background-color: transparent;
	cursor: pointer;
	font-size: xx-large;
	box-shadow: none;
}

.review_header_btn:hover {
	outline: none;
	background: none;
}

.customBackButton {
	font-size: 50px;
}

.backArrow {
	width: 20px;
	height: 20px;
	color: var(--primary-01);
}

.backText {
	color: var(--primary-01);
	font-size: 20px;
}

.toggleDropdowns {
	border: none;
	padding: 10px;
	color: var(--primary-01);
}

.button_container {
	padding: 15px;
	background-color: var(--green-01);
	color: #ffff;
	border: none;
	cursor: pointer;
}

.approveRevoke_btn {
	display: flex;
	gap: 24px;
	float: inline-end;
	margin-top: 78px;
	margin-bottom: 85px;
}

.approve__btn {
	width: 150px;
}

.revoke__btn {
	width: 150px;
	background-color: #d73e3e !important;
	color: #ffff !important;
	border-radius: 4px;
	border: 1px solid var(--red-02);
}

.only_filter {
	float: right;
}

.declineMiniModal {
	width: 344px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	float: inline-end;
	margin-top: -80px;
	background-color: #ffff;
	box-shadow: 0px -2px 15px rgba(0, 37, 73, 0.2);
	margin-right: -220px;
}

.revokeMiniModal {
	width: 339px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	float: inline-end;
	margin-top: -70px;
	background-color: #ffff;
	box-shadow: 0px -2px 15px rgba(0, 37, 73, 0.2);
	margin-right: -70px;
}

.miniModal p {
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	line-height: 22.65px;
}

.miniModalBtns {
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-top: 16px;
}

.miniModalBtns button {
	width: 80px;
	padding: 4px 20px;
	color: #ffff;
	outline: none;
	border: none;
	border-radius: 4px;
	border: 1px solid #d8d8d8;
}

.approvalSuccess__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 20px;
	background-color: #f2d4d4;
	height: 66px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.approvalSuccess__badge h3 {
	font-size: 16px;
	font-weight: 600;
	line-height: 19.36px;
	text-transform: uppercase;
}

.content {
	margin-top: 70px;
}

.content p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
	color: #000000;
}

.content label {
	margin-top: 20px;
	font-size: 14px;
	font-weight: 500;
	line-height: 22.7px;
	float: initial;
}

.content input {
	border: 1px solid #5e7687;
	padding: 8px 10px;
}

.content button {
	display: flex;
	align-items: center;
}

.continueBtn {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 24px;
}

.contBtn {
	padding: 8px 50px;
}
