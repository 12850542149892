.revoke__warning {
	margin-top: 20px;
}

.revoke__btns {
	margin-top: 30px;
	margin-bottom: 20px;
	display: flex;
	justify-content: center;
	gap: 20px;
}

.revoke__btns button {
	padding: 0 30px;
}

.revoke__btns button:last-of-type {
	background-color: var(--red-01);
	border: none;
	color: var(--neutrals-white);
	border-radius: 4px;
}
