.TCC__verification_page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-color: #eff3f7;
	padding: 0 16px;
}

.TCC__verification_page > img {
	height: 100px;
	width: 100px;
	margin-bottom: 30px;
}

.TCC__verification_page > h1 {
	font-size: 24px;
	font-weight: 700;
	color: #0c0c0c;
}

.TCC__verification_page > form {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 400px;
	margin-top: 20px;
}

.form input {
	margin-bottom: 15px;
	border-radius: 8px;
	background-color: transparent;
	height: 49px;
}

.form button {
	border-radius: 8px !important;
	font-size: 18px !important;
	height: 55px;
}
