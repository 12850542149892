.modal {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	background-color: var(--neutrals-black);
	opacity: 0.7;
}

.children {
	z-index: 2;
	position: absolute;
	top: 125px;
	left: 40px;
	width: calc(100% - 80px);
	height: calc(100vh - 305px);
	background-color: var(--white);
	overflow: auto;
	border-radius: 8px;
}

.regular__children {
	z-index: 2;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 24px 36px;
	background-color: var(--white);
	border-radius: 4px;
	overflow-y: auto;
	max-height: 90vh;
}

.invoice__children {
	z-index: 2;
	position: absolute;
	top: 80vh;
	left: 20vw;
	padding: 24px 36px;
	background-color: var(--white);
	border-radius: 4px;
}

.close__btn {
	position: absolute;
	top: calc(calc(100vh - 305px) + 200px);
	height: 50px;
	width: 255.22px;
	background-color: transparent;
	border: 1px solid var(--primary-01);
	border-radius: 4px;
	color: var(--primary-01);
}

.small__modal {
	width: 474px;
	height: 547px;
	top: 50%;
	left: 50%;
	position: fixed;
	transform: translate(-50%, -50%);
}

.regular__children input {
	width: 100%;
	height: 50px;
}

.payment__modal {
	z-index: 2;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--white);
	border-radius: 4px;
	overflow-y: auto;
	max-height: 90vh;
	width: 90%;
	max-width: 514px;
}

.payment__modal_header {
	display: flex;
	align-items: center;
	padding: 0 20px;
	background-color: var(--grey-10);
	height: 64px;
	font-size: 20px;
	color: var(--white);
}

.payment__modal_header img:first-of-type {
	margin-right: 12px;
}

.payment__modal_header img:last-of-type {
	margin-left: auto;
}

.payment__options_form {
	padding: 24px 32px 32px;
}

.payment__options_form select {
	width: 100%;
}

.payment__options_form button {
	display: block;
	margin-left: auto;
	margin-top: 24px;
	padding: 0 48px;
}

@media (max-width: 768px) {
	.regular__children {
		padding: 24px 16px;
		top: 10%;
		left: 10%;
		transform: translate(-5%, -5%);
	}

	.payment__options_form {
		padding: 24px;
	}

	.payment__options_form button {
		width: 100%;
	}
}
