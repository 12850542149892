.footer__home {
	width: 100%;
	display: block;
	padding: 30px 20px;
	margin-right: 15px !important;
}

.footer__home > div > h5 {
	color: rgba(22, 73, 43, 1);
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 20px;
}

.footer__item {
	display: flex;
	background: transparent;
	border: 0;
	font-weight: 500;
	cursor: pointer;
	margin-bottom: 15px;
}

.footer__item a {
	font-size: 14px;
	color: rgba(32, 32, 32, 1);
	cursor: pointer;
}

.socialMedia {
	background: transparent;
	border: none;
	list-style: none;
}

.socialMedia img {
	width: 24px;
	height: 24px;
}

.socialMedia a:nth-child(2),
.socialMedia a:nth-child(3) {
	margin-left: 10px;
}

.credits {
	display: block;
	text-align: center;
	padding: 20px;
	margin-top: 20px;
	margin-bottom: 80px;
}

.credits p {
	margin-bottom: 10px;
}

@media (min-width: 768px) {
	.footer__home {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		padding: 50px 40px;
	}

	.footer__home > div {
		flex: 2 2 45%;
		margin-bottom: 20px;
	}

	.credits {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 118px;
	}
}

@media (min-width: 1024px) {
	.footer__home {
		width: 100%;
		display: flex;
		gap: 40px;
		padding: 64px 118px 20px 118px;
	}

	.footer__home > div {
		flex: 1;
	}
}
