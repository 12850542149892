.personalTaxIncome {
	padding: 20px 5%;
	background-color: rgba(249, 249, 249, 1);
	margin-top: 30px;
	width: 100%;
	display: block;
	align-items: center;
}

.personalTaxIncome img {
	width: 100%;
}

.personalTaxIncome h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: -0.03em;
	color: rgba(12, 12, 12, 1);
}

.paragraphs {
	width: 100%;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.paragraphs p {
	margin-top: 32px;
	font-size: 16px;
	color: #202020;
}

.standAlone {
	max-width: 100%;
}

@media (min-width: 768px) {
	.personalTaxIncome {
		padding: 70px 120px;
		display: flex;
		flex-direction: column;
		gap: 50px;
	}

	.personalTaxIncome h2 {
		font-size: 24px;
		line-height: 28px;
		margin-top: 40px;
	}

	.paragraphs p {
		margin-top: 40px;
		font-size: 20px;
		line-height: 25px;
	}
}

@media (min-width: 1024px) {
	.personalTaxIncome {
		padding: 70px 120px;
		flex-direction: row;
		justify-content: space-between;
	}
	.personalTaxIncome img {
		width: 45%;
	}

	.personalTaxIncome h2 {
		font-size: 28px;
		line-height: 34px;
	}

	.paragraphs {
		width: 600px;
	}

	.paragraphs p {
		margin-top: 40px;
		font-size: 16px;
	}

	.standAlone {
		max-width: 505px;
	}
}
