.table__wrapper {
	overflow-x: auto;
}

.table {
	width: 100%;
}

.table thead tr {
	background-color: var(--green-00);
	white-space: nowrap;
	/* white-space: normal; */
}

.table thead tr th {
	text-align: left;
}

.table thead th {
	color: var(--neutrals-black);
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
}

.table,
.table td,
.table th {
	border-collapse: collapse;
}

.table th,
.table td {
	padding: 15px 15px 15px 20px;
	width: fit-content;
}

.table tbody {
	background-color: var(--neutrals-white);
}

.table tbody td {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: var(--neutrals-black);
	border-right: 1px solid var(--light-grey-00-0);
	border-bottom: 1px solid var(--light-grey-00-0);
}

.table__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
	color: #020808;
	padding: 10px;
}

.pagination {
	display: flex;
	align-items: center;
}

.icon__button {
	border: 1px solid var(--grey-01);
	border-radius: 4px;
	height: 30px;
	padding: 6.5px 11px;
	background: none;
	outline: none;
	cursor: pointer;
	font-size: 12px !important;
	font-weight: 500;
	line-height: 16px !important;
	color: var(--grey-10);
	margin-left: 5px;
	margin-right: 5px;
}

.icon__button:hover {
	box-shadow: none;
}

.icon__button.active {
	background: var(--green-02);
	color: #fff;
}

.arrow__left {
	background: url(../../assets/arrowLeft.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
}

.arrow__right {
	background: url(../../assets/arrowRight.svg);
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
}

.edit__btn,
.view__btn,
.approve_btn,
.revoke__btn {
	background-color: transparent;
	border: none;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	padding: 0 10px;
	margin-bottom: 24px;
	font-size: 15px;
	margin-bottom: 0;
}

.edit__btn {
	color: var(--primary-05);
}

.view__btn {
	color: var(--primary-07);
}

.revoke__btn {
	color: var(--red-01);
}

.edit__btn:hover,
.view__btn:hover,
.revoke__btn:hover {
	box-shadow: none;
	text-decoration: underline;
}

@media (max-width: 768px) {
	.table {
		width: fit-content;
		border: 1px solid red;
	}
	.table thead tr {
		width: 50%;
		white-space: nowrap;
		/* white-space: normal; */
	}
}
