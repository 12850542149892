.searchForm {
	width: 100%;
	padding-right: 16px !important;
}

.input {
	width: 300px;
	margin-bottom: 15px;
}

.search__btn {
	display: block;
	width: 300px;
}

.details {
	margin-top: 40px;
	margin-bottom: 60px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.details > div {
	display: flex;
	flex-direction: column;
}

.details > div > b {
	color: var(--light-grey-04) !important;
}

.action__btns {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

.action__btns button {
	padding: 5px 15px;
}

.payeForm {
	display: flex;
	gap: 15px;
	border-top: 1px solid var(--diluted-primary-01);
	padding-top: 20px;
	padding-right: 16px;
	padding-bottom: 30px;
}

.payeForm > div {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.payeForm > div > button {
	margin-top: 10px;
	margin-left: auto;
}

.uploadPaye {
	border: 2px solid red;
}
@media (max-width: 768px) {
	.input {
		width: 100%;
	}

	.search__btn {
		display: block;
		width: 100%;
	}

	.details {
		flex-direction: column;
	}

	.details > div {
		margin-bottom: 15px;
		width: 45%;
	}

	.action__btns {
		flex-direction: column;
		gap: 15px;
	}

	.action__btns button {
		width: calc(100% - 16px);
	}

	.payeForm {
		flex-direction: column;
	}

	.payeForm > div > button {
		width: 100%;
		margin-top: 15px;
	}
}
