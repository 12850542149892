.receipt__page {
	margin-bottom: 40px;
}

.form {
	width: fit-content;
	height: fit-content;
	margin-bottom: 90px;
}

.send__receipt__btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--primary-01);
	background: transparent;
	border: none;
}

.submit__btn {
	width: calc(50% - 18px);
}

.receipt > table {
	margin: 0 !important;
}

.receipt__view {
	display: flex;
}

.receipt__view > table {
	margin: 0 !important;
}

.radio__button_div {
	margin-bottom: 20px;
}

.radio__button_div > span {
	font-weight: 600;
}

.radio__button_div > div {
	display: flex;
}

.radio__button_div > div > div {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.radio__button_div > div > div input {
	margin-right: 10px;
}

.radio__button_div > div > div label {
	margin-top: -10px;
}

.radio__button_div > div > div {
	margin-right: 30px;
}

.print__btn {
	display: block;
	margin: 20px auto;
	width: 100px;
}

@media (max-width: 768px) {
	.submit__btn {
		width: 100%;
	}
}
