.signup_page {
	display: flex;
	color: var(--light-grey-08);
}

.auth__form__container {
	width: 50%;
	margin-left: calc(30% + 108px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 110px;
}

.signup_page > main > h1 {
	font-weight: 600;
	font-size: 36px;
	line-height: 44px;
	margin-bottom: 12px;
	color: var(--green-10);
}

.body > p {
	max-width: 410px;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 20px;
	color: var(--grey-06);
}

.form {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 77px;
}

.form .double__inputs,
.form .triple__inputs {
	display: flex;
	gap: 24px;
}

.double__inputs > div {
	width: calc(50% - 12px);
}

.triple__inputs > div {
	width: calc(33.33% - 8px);
}

.form input,
.form select {
	margin-bottom: 24px;
	width: 100%;
}

.form .hr {
	height: 1px;
	width: 100%;
	margin-top: 12px;
	margin-bottom: 36px;
	background-color: var(--grey-01);
	border: none;
}

.btn__div {
	display: flex;
	gap: 19px;
	width: calc(50% - 12px);
	margin-left: auto;
	margin-top: 16px;
}

.btn__div button {
	flex: 1;
}

.camera__area {
	display: flex;
	gap: 20px;
	height: 80%;
}

.camera__area > div:first-of-type {
	position: relative;
}

.canvas__play,
.video__snap_position {
	position: absolute;
	top: 80px;
	left: 0;
	height: calc(100% - 170px);
	width: 100%;
}

.picture__preview,
.preview {
	height: calc(100% - 115px);
	width: 100%;
}

.video__snap_position {
	border: 4px dotted #f00;
}

.continue__btn {
	width: 100%;
	margin-top: 20px;
}

.capture__btn {
	width: 100%;
}

.clear__btn {
	width: 100%;
}

.profile__img {
	border-radius: 8px;
	height: 200px;
	width: 175px;
}

.take__photo_btn {
	display: block;
	margin-top: 15px;
	margin-bottom: 20px;
	width: 175px;
}

.regular__text {
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: var(--neutrals-black);
}

.radio__label {
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: var(--light-grey-08);
	margin-left: 8px;
}

.divider {
	width: 100%;
	height: 1px;
	background-color: var(--grey-01);
	border: 0;
	margin: 36px 0;
}

.div__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 1px solid var(--secondary-02);
	width: 100%;
	margin-bottom: 1.9rem;
}

.select__wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 40%;
}

/* Tooltip */

.tooltip {
	height: 10px;
	width: 10px !important;
	position: relative;
	display: inline-block;
	background-color: black;
	margin-left: 5px;
	font-size: 7px;
	font-weight: 900;
	border-radius: 50%;
	color: #fff;
	padding: 0;
	text-align: center;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 250px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 10px;
	position: absolute;
	z-index: 1;
	top: -30px;
	left: 14px;
	font-size: 13px;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

/* Tooltip */

.input__medium {
	width: 19.8rem;
	margin-bottom: 42px;
}

.input__long {
	margin-bottom: 42px;
}

.input__short {
	width: 9.9rem !important;
	margin-bottom: 50px;
}

input[type="date"].input__short {
	width: 10.9rem !important;
}

.div__wrapper__input__short {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--secondary-02);
	width: 100%;
	margin-bottom: 1.9rem;
}

.div__wrapper__input__short .input__short {
	margin-right: 2.8rem;
}

.input__label {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	margin-bottom: 8px;
	color: var(--light-grey-08);
}

.columned__form {
	display: flex;
	flex-direction: column;
	max-width: 736px;
}

.columned__form input,
.columned__form select {
	background: transparent;
	margin-bottom: 24px;
}

.columned__form .double__inputs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	column-gap: 36px;
}

.columned__form .double__inputs > div {
	display: flex;
	flex-direction: column;
	width: calc(50% - 18px);
}

input[long="true"] {
	width: 100%;
}

.add__another__child__btn {
	border: none;
	width: 166px;
	background-color: transparent;
	color: var(--primary-07);
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
}

.add__another__child__btn:hover {
	box-shadow: none;
}

.add__another__child__btn:disabled {
	background-color: transparent !important;
}

.file__name {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
	color: #e69e00;
}

@media (max-width: 768px) {
	.signup_page {
		flex-direction: column;
	}

	.auth__form__container {
		margin: 0px;
		width: 100%;
		padding-top: 20px;
	}

	.auth__form__container button:first-of-type {
		margin-left: 16px;
	}

	main.auth__form__container > h1 {
		font-size: 24px;
		line-height: 29px;
		margin: 40px auto 8px;
	}

	.profile__img {
		margin-top: 15px;
	}

	.body {
		padding: 0 16px;
	}

	.body > p {
		max-width: 289px;
		margin: 0 auto 24px;
		text-align: center;
	}

	.tabs {
		justify-content: center;
		margin-bottom: 35px;
	}

	.form .double__inputs,
	.form .triple__inputs,
	.columned__form .double__inputs {
		flex-direction: column;
		margin-bottom: 24px;
	}

	.double__inputs > div,
	.triple__inputs > div {
		width: 100%;
	}

	.form input,
	.form select {
		margin-bottom: 0;
	}

	.form > input,
	.form > select {
		margin-bottom: 24px;
	}

	.btn__div {
		gap: 20px;
		width: 100%;
		margin-left: 0;
	}

	.camera__area {
		flex-direction: column;
	}

	.mobile__canvas {
		display: none !important;
	}

	.columned__form {
		width: calc(100% - 16px);
	}

	.columned__form .double__inputs > div {
		width: 100%;
		margin-right: 0;
	}

	.columned__form .double__inputs > div:first-of-type {
		margin-right: 0;
	}

	.input__medium,
	.input__short {
		width: 100%;
	}
}
