.wrapper {
	width: 100%;
}

.double__inputs {
	display: flex;
	gap: 24px;
}

.wrapper > p {
	font-weight: 600;
	margin-bottom: 20px;
	padding-bottom: 10px;
	color: var(--green-03);
	border-bottom: 1px solid var(--light-grey-09);
}

.notice__left {
	padding: 18px 32px;
}

/* .notice__left > p {
	padding-left: 23px;
} */

.notice__center {
	padding: 18px 18px 10px 0px;
	text-align: center;
}

.notice__center p {
	margin: 0px;
	text-transform: uppercase;
}

.info {
	padding: 18px 18px 10px 18px;
}

.notice li {
	margin: 0px 0px 5px 0px;
}

.uploads {
	/* display: flex;
    justify-content: space-between; */
	margin: 10px 0px;
	padding: 15px;
	background-color: var(--off-white);
}

.uploads > div {
	margin: 15px 0px;
}

.uploads > div > p {
	text-transform: uppercase;
}

.uploads > div > span > label,
.uploads > div > span > label > p {
	margin: 0px;
}

.uploads > div > span > span > p {
	margin: 0px;
	font-weight: 500;
}

.authority {
	/* display: flex;
    justify-content: space-between; */
	margin: 10px 0px;
	padding: 15px;
	background-color: var(--off-white);
}

.authority > div {
	margin: 15px 0px;
}

.authority > div > p {
	text-transform: uppercase;
}

.authority > div > span > span > p {
	margin: 0px;
	font-weight: 500;
}

.entries__title {
	color: var(--dark-blue-01);
	font-size: 600;
}

.ml_1 {
	margin-left: 1rem;
}

.downloads__button {
	display: flex;
	font-size: 11px;
	align-items: center;
	background-color: var(--green-04) !important;
	width: max-content;
	padding: 13px;
	font-size: 14px;
	font-weight: 600;
}

.downloads__button img {
	width: 14px;
}

.downloads__button p,
.border__button__green p {
	margin: 0px 5px 0px 5px;
}

.border__button__green {
	display: flex;
	align-items: center;
	color: var(--green-09);
	background-color: var(--white);
	border: 1px solid var(--green-09);
}

.agreement {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.agreement input {
	height: auto;
	margin: 6px 10px 0px 0px;
}

.delete_icon {
	width: 20px;
}
