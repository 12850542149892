.heroSection {
	width: 100%;
	background-image: url(../../assets/Home/legislationBgMobile.svg);
	background-size: cover;
	background-repeat: no-repeat;
	height: 370px;
	padding: 183px 20px 36px 20px;
	color: #fff;
}

.heroSection h2 {
	font-size: 30px;
}

.heroSection h3 {
	font-size: 14px;
	margin-top: 12px;
	line-height: 24px;
}

.desktop {
	display: none;
}

.mobile {
	display: block;
}

@media (min-width: 1024px) {
	.heroSection {
		width: 100%;
		background-image: url(../../assets/Home/legislationBgImg.svg);
		height: 400px;
		padding: 198px 0 80px 118px;
	}

	.heroSection h2 {
		font-size: 48px;
		font-weight: 600;
		line-height: 58.09px;
		color: rgba(255, 255, 255, 1);
	}

	.heroSection h3 {
		font-size: 18px;
		font-weight: 400;
		line-height: 25.89px;
		color: rgba(255, 255, 255, 1);
	}

	.desktop {
		display: block;
	}

	.mobile {
		display: none;
	}
}

/* legislation Download Cards */
.legislationLawsContainer {
	display: block;
	padding: 24px 20px;
}

.legislationCards {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.legislationCards h2 {
	font-size: 16px;
	font-weight: 600;
	color: rgba(0, 0, 0, 1);
}

.legislationImg {
	width: 100%;
}

.legislationCards:nth-child(n + 2) {
	margin-top: 40px;
}

@media (min-width: 768px) {
	.legislationLawsContainer {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding: 118px;
	}

	.legislationCards:nth-child(n + 2) {
		margin-top: 0;
	}
}
