@keyframes animLeft {
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0%);
	}
}

.slideInLeft.inView {
	animation: animLeft 500ms;
	animation-timing-function: linear;
}

@keyframes animRight {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
}

.slideInRight.inView {
	animation: animRight 500ms;
	animation-timing-function: linear;
}
