.form {
	width: calc(100% - 16px);
}

.radio__inputs {
	display: flex;
	align-items: center;
	margin-bottom: 32px;
}

.radio__inputs input {
	margin: 0;
	margin-right: 8px;
}

.radio__inputs label {
	margin-right: 24px;
	margin-bottom: -3px;
}

.serial__number_input {
	display: block;
	width: 100%;
	margin-bottom: 32px;
}

.form button {
	width: 100%;
}

@media (min-width: 768px) {
	.form {
		width: 35%;
	}
}
