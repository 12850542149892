.corporatePayeRecord {
	position: relative;
}

.header__btns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}

.header__btns .filter__button {
	display: flex;
	align-items: center;
	border: none;
	background: transparent;
	color: var(--green-01);
}
.review_header {
	font-weight: 900;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.review_header_btn {
	border: none;
	border: none;
	color: var(--primary-01);
	background-color: transparent;
	cursor: pointer;
	font-size: xx-large;
}

.customBackButton {
	font-size: 50px;
}

.backArrow {
	width: 20px;
	height: 20px;
	color: var(--primary-01);
}

.backText {
	color: var(--primary-01);
	font-size: 20px;
}

.toggleDropdowns {
	border: none;
	padding: 10px;
	color: var(--primary-01);
}

.button_container {
	padding: 15px;
	background-color: var(--green-01);
	color: #ffff;
	border: none;
	cursor: pointer;
}

.auth_btn {
	width: 10%;
	display: flex;
	justify-content: space-between;
}

.approve__btn {
	height: 47px !important;
	width: 197px;
}

h4 {
	margin: 0;
}

.downloadSec {
	display: flex;
	justify-content: space-between;
}

.voucher {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.voucher h4 {
	color: #34aa63;
	font-size: 14px;
	font-weight: 500;
	margin-left: 5px;
}

.closeBillbtn {
	display: flex;
	justify-content: center;
	cursor: pointer;
}
/* ////////// modal  ////////////// */

.body {
	width: 100vh;
}

.bodyOverlay {
	position: fixed;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(rgb(119, 119, 119), green, blue, alpha);
	justify-content: center;
	align-items: center;
	z-index: 3;
}

.modalOverlay.visible {
	display: flex;
}

.modalContent {
	width: max-content;
	position: absolute;
	background-color: #fefefe;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 800px;
	height: calc(100vh - 170px);
	overflow-y: auto;
}

.approvalSuccess__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 30px;
	background-color: #0c0c0c;
	height: 64px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.imageText {
	display: flex;
	align-items: center;
}

.imageText h3 {
	color: #ffffff;
	margin-left: 12px;
	font-size: 20px;
	line-height: 24.2px;
}

.approvalSuccess__badge button,
.approvalSuccess__badge img {
	border: none;
	background-color: #0c0c0c;
}

.white {
	background-color: #ffffff;
	height: 250px;
	border: 2px solid black;
}

.selectText {
	display: flex;
	align-items: center;
	margin-top: 20px;
	margin-left: 20px;
}

.selectText span {
	margin-left: 5px;
}

.dropdown {
	margin-left: 20px;
}

.visible {
	display: flex;
}

.receiptBtn {
	display: inline-flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 30%;
	margin-top: 30px;
	margin-bottom: 40px;
}

.actionBtns {
	display: flex;
	flex-direction: row;
	margin-top: 24px;
	margin-bottom: 30px;
	float: inline-end;
	gap: 18px;
}

.actionBtns button {
	padding: 8px 20px;
	background-color: #34aa63;
	color: #ffffff;
	border-radius: 4px;
	border: 1px solid #34aa63;
}

.companyDetailsHeader {
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	color: #0c0c0c;
	margin-bottom: 23px;
}

.viewCompanyDetails {
	display: flex;
	justify-content: space-between;
}

.viewCompanyDetails h4 {
	margin: 0;
	font-size: 14px;
	line-height: 22.7px;
}

.employeeColumn,
.employeeColumn1Contents {
	display: flex;
}

.employeeColumn2 {
	margin-left: 180px;
}

.employeeDetails {
	display: flex;
	justify-content: space-between;
}

.employeeDetail {
	display: flex;
}

.detailsKey {
	color: #3f3f3f;
	font-weight: 400;
	font-size: 14px;
	line-height: 22.56px;
	width: 180px;
	margin-bottom: 15px;
}
.detailsValue {
	font-size: 14px;
	font-weight: 600;
	line-height: 22.7px;
	color: #202020;
}

@media (max-width: 768px) {
	.receiptBtn {
		width: 100%;
		padding: 10px;
	}
	.paye__btn {
		width: 100%;
		margin-top: 20px;
	}
	.employeeDetails {
		display: block;
	}
}

/* ///////////////////PAYMENT STYLE/////////////// */
.form {
	padding: 50px;
}

.btn {
	width: 110px;
	height: 50px;
	text-align: center;
	background-color: rgba(52, 170, 148, 1);
	color: white;
	border-radius: 4px;
	border: none;
}

.paymentIcon {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.paymentIcon > img {
	width: 50px;
}

.goBack {
	cursor: pointer;
}

.adjust {
	padding-left: 40px;
}

.wrapper {
	padding: 10px 10px 40px 10px;
	box-shadow: 0px 12px 20px 0px rgba(31, 48, 63, 0.1);
	position: relative;
}

.head {
	padding: 40px;
	background-color: var(--dark-blue-07);
}

.align {
	display: flex;
}

.row {
	height: 100vh;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #edf3f7;
}

.interswitch__header {
	padding: 10px;
	background-color: rgba(22, 71, 130, 1);
}

.remita__header {
	padding: 10px;
	background-color: rgb(163 71 7);
}

.unifiedpayment__header {
	padding: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	margin: 15px 0px 15px 0px;
	background-color: rgb(255, 255, 255);
}

/* ///////////should go /////////// */
.interswitch__header > img,
.remita__header > img,
.flutterwave__header > img,
.unifiedpayment__header {
	height: 40px;
}
/* ///////////should go /////////// */

.divider {
	width: 100%;
	height: 1px;
	background: rgb(0 0 0 / 4%);
	border: 0;
	margin: 22px 0px 10px 0;
}

.backdrop {
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
}
.home__btn {
	display: block;
	position: absolute;
	top: 40px;
	left: 40px;
	padding: 10px 20px;
}

.custom__alert {
	top: 200px;
	left: 30%;
	width: 40%;
	position: fixed;
	background-color: var(--white);
	border: 10px;
	padding: 40px 40px 40px 40px;
	box-shadow: 0px 25px 40px 0px rgba(31, 48, 63, 0.1);
}

/* styles.module.css */
.status-0 {
	color: orange; /* Pending */
	text-transform: uppercase;
}
.status-1 {
	color: #d73e3e; /* Cancel */
	text-transform: uppercase;
}
.status-2 {
	color: #d73e3e; /* Decline */
	text-transform: uppercase;
}
.status-3 {
	color: #2f9959; /* Approved */
	text-transform: uppercase;
}
.status-4 {
	color: #d73e3e; /* Revoked */
	text-transform: uppercase;
}
.status-5 {
	color: #f99601; /* Pending Approval */
	text-transform: uppercase;
}
.status-6 {
	color: gray; /* Deleted */
	text-transform: uppercase;
}

.approveRevoke_btn {
	display: flex;
	gap: 24px;
	float: inline-end;
	margin-top: 20px;
	margin-bottom: 85px;
}

.revoke__btn {
	height: 47px !important;
	width: 150px;
	background-color: #d73e3e !important;
	color: #ffff !important;
	border-radius: 4px;
	border: none !important;
}

.cancelMiniModal {
	width: 353px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 24px;
	background-color: #ffff;
	box-shadow: 0px -2px 15px rgba(0, 37, 73, 0.2);
	margin-left: 630px;
	margin-top: -158px;
	position: absolute;
}

.miniModal p {
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	line-height: 22.65px;
}

.miniModalBtns {
	display: flex;
	flex-direction: row;
	gap: 16px;
	margin-top: 16px;
}

.miniModalBtns button {
	width: 80px;
	padding: 4px 20px;
	color: #ffff;
	outline: none;
	border: none;
	border-radius: 4px;
	border: 1px solid #d8d8d8;
}

.approvalSuccess__badge {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding-left: 20px;
	background-color: #0c0c0c;
	height: 66px;
	display: flex;
	align-items: center;
	gap: 8px;
}

.approvalSuccess__badge h3 {
	font-size: 16px;
	font-weight: 600;
	line-height: 19.36px;
	text-transform: uppercase;
}

.content {
	margin-top: 70px;
}

.content p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
	color: #000000;
}

.content label {
	margin-top: 20px;
	font-size: 14px;
	font-weight: 500;
	line-height: 22.7px;
	float: initial;
}

.content input {
	border: 1px solid #5e7687;
	padding: 8px 10px;
}

.content button {
	display: flex;
	align-items: center;
}

.continueBtn {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 24px;
}

.contBtn {
	padding: 8px 50px;
}

@media (min-width: 1080px) {
	.custom__alert {
		width: 60%; /* Adjust the width for large screens */
	}
}

@media (min-width: 600px) {
	.custom__alert {
		width: 40%; /* Adjust the width for medium screens */
	}
}

@media (max-width: 400px) {
	.custom__alert {
		width: 20%;
		left: 40%; /* Adjust the width for smaller screens */
	}
}

.payments__double__inputs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0px 30px 0px;
}

.payments__double__inputs input {
	height: 35px;
	border: 1px solid rgb(94 118 135 / 40%);
}

.esbn__selector {
	display: flex !important;
	justify-content: flex-start;
	align-items: center;
	margin-right: 20px;
}

.esbn__selector label {
	margin: 0px 0px 0px 5px;
	font-size: 12px;
}

.esbn__selector > input[type="radio"] {
	accent-color: rgb(18 167 139);
	width: 18px;
	height: 19px;
	margin: 0;
}

.payments__double__inputs input::placeholder {
	color: rgba(158, 158, 158, 1);
	font-size: 12px;
	border: none;
}

.payments__double__inputs input:disabled {
	border: none;
	background-color: rgba(237, 243, 247, 1);
}

.payments__double__inputs > div {
	width: calc(50% - 18px);
	display: flex;
	flex-direction: column;
	/* margin-top: 10px; */
}

.payments__double__inputs > div:first-of-type {
	margin-right: 36px;
}

@media (max-width: 768px) {
	.payments__double__inputs > div {
		width: 100%;
		margin-right: 0;
	}

	.payments__double__inputs > div:first-of-type {
		margin-right: 0;
	}
	.employeeColumn {
		display: block;
		/* border: 2px solid purple */
	}
}
