.wrapper {
	background: #fffdfa;
}

.top__bar,
.payment__gateways_breadown {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.payment__gateways_breadown {
	margin-top: 25px;
	margin-bottom: 20px;
}

/* ----------- femi  Dashboard breakdowns  */
.breakdown__amt span:last-of-type {
	position: relative;
}

.breakdown__amt span.tooltip {
	position: absolute;
	top: 18px;
	right: 0;
	height: 250px;
	width: 90vw;
	max-width: 300px;
	border-radius: 8px;
	background-color: var(--neutrals-white);
	overflow: auto;
	z-index: 1;
	box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.2);
}

.breakdown__amt span.tooltip ul li {
	font-size: 12px;
	margin-bottom: 0px;
}

.breakdown__amt span.tooltip ul li b {
	font-size: 12px;
	width: 40%;
}
/* ----------- femi  Dashboard breakdowns */

.top__card,
.payment__gateways_breadown > div {
	display: flex;
	flex-direction: column;
	padding: 24px;
	width: 23%;
	background-color: var(--neutrals-white);
}

.top__card > img {
	height: 36px;
	width: 36px;
	margin-bottom: 24px;
}

.top__card p {
	margin: 0;
}

.numbers {
	margin-bottom: 45px;
}

.numbers p:first-of-type {
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-06);
	margin-bottom: 4px;
}

.numbers p:last-of-type {
	font-weight: 600;
	font-size: 18px;
	line-height: 23px;
	color: var(--dark-blue-10);
}

.top__card > p {
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-06);
}

.target__filter {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	margin-top: 60px;
}

.target__table {
	margin-top: 20px;
	overflow-x: auto;
}

.payment__gateways_breadown > div {
	justify-content: space-between;
}

.payment__gateways_breadown > div > div {
	margin-bottom: 10px;
}

.payment__gateways_breadown > div > div > div {
	display: flex;
	justify-content: space-between;
}

.payment__gateways_breadown > div > p {
	color: var(--light-grey-06);
	font-size: 14px;
}

.payment__gateways_breadown .gateway__name {
	font-size: 12px;
}

.payment__gateways_breadown .gateway__amount {
	font-weight: 600;
	font-size: 12px;
	text-align: right;
	margin-right: auto;
	color: var(--dark-blue-10);
}

.payment__gateways_breadown .gateway__percentage {
	font-size: 12px;
	font-weight: 500;
	color: var(--secondary-09);
	text-align: right;
}

.payment__gateways_breadown .interswitch .gateway__name {
	color: #2e4862;
}

.payment__gateways_breadown .remita .gateway__name {
	color: #cd6e43;
}

.payment__gateways_breadown .flutterwave .gateway__name {
	color: #d7a64b;
}

.payment__gateways_breadown .eTransact .gateway__name {
	color: #9e3d3c;
}

.payment__gateways_breadown .QTB .gateway__name {
	color: #cf352e;
}

.payment__gateways_breadown .UP .gateway__name {
	color: #68a8e4;
}

.third__section {
	margin-bottom: 40px;
}

.pie,
.bar {
	width: 280px;
	height: 280px;
}

.anssid__div {
	display: flex;
	justify-content: space-between;
	margin-bottom: 38px;
}

.anssid__stat {
	background-color: var(--neutrals-white);
	padding: 24px;
	padding-bottom: 12px;
	width: 30%;
}

.anssid__stat > img {
	margin-bottom: 24px;
}

.anssid__stat > div p:first-of-type {
	font-weight: normal;
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-06);
	margin: 0;
}

.anssid__stat > div p:last-of-type {
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: var(--dark-blue-10);
	margin: 0;
	margin-bottom: 45px;
}

.anssid__stat > p {
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-06);
	display: flex;
	justify-content: space-between;
}

.graph__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.graph__header select {
	width: 40%;
}

.graph__header p {
	margin: 0;
}

.breakdowns {
	display: flex;
	justify-content: space-between;
	margin-bottom: 60px;
}

.breakdowns > div {
	width: calc(50% - 18px);
}

.breakdowns > div h3 {
	font-weight: 500;
	font-size: 18px;
	line-height: 78px;
	padding-left: 24px;
	margin-bottom: 0;
	color: var(--dark-blue-05);
	background-color: var(--lights-03);
}

.breakdowns > div ul {
	list-style-type: none;
	padding-left: 0px;
	margin-top: 0;
	max-height: 594px;
	overflow-y: auto;
}

.breakdowns > div ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: normal;
	font-size: 15px;
	padding-left: 24px;
	padding-right: 24px;
	height: 74px;
	margin-bottom: 4px;
	color: var(--neutrals-black);
	background-color: var(--neutrals-white);
}

@media (max-width: 768px) {
	.top__bar,
	.anssid__div {
		flex-wrap: wrap;
		width: calc(100vw - 32px);
		margin-top: -16px;
	}

	.anssid__div {
		margin-top: -36px;
	}

	.top__card,
	.anssid__stat {
		width: calc(50% - 7.5px);
		height: 179px;
		margin-bottom: 16px;
		padding: 12px;
	}

	.top__card > img,
	.anssid__stat > img {
		height: 24px;
		width: 24px;
		margin-bottom: 23px;
	}

	.anssid__stat > img {
		margin-bottom: 32.5px;
	}

	.target__filter {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding-right: 16px;
	}

	.target__filter button {
		display: block;
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.target__table {
		margin-bottom: 40px;
	}

	.numbers {
		margin-bottom: 42px;
	}

	.numbers p:first-of-type,
	.anssid__stat > div p:first-of-type {
		font-size: 10px;
		line-height: 16px;
		margin-bottom: 2px;
	}

	.numbers p:last-of-type,
	.anssid__stat > div p:last-of-type {
		font-weight: bold;
		font-size: 15px;
		line-height: 29px;
		color: var(--neutrals-black);
	}

	.anssid__stat > div p:last-of-type {
		margin-bottom: 32.5px;
	}

	.top__card > p,
	.anssid__stat > p {
		font-size: 10px;
		line-height: 16px;
	}

	.breakdowns {
		flex-direction: column;
		width: calc(100vw - 32px);
		margin-top: -36px;
	}

	.breakdowns > div {
		width: 100%;
	}

	.breakdowns > div h3 {
		font-weight: 500;
		font-size: 14px;
		line-height: 23px;
		padding: 24px 12px;
		margin-top: 0;
	}

	.breakdowns > div ul li {
		font-weight: normal;
		font-size: 14px;
		line-height: 23px;
		height: 71px;
		padding-left: 12px;
	}

	.payment__gateways_breadown {
		margin-top: 10px;
		flex-direction: column;
		gap: 20px;
		width: calc(100vw - 32px);
		margin-bottom: 60px;
	}

	.payment__gateways_breadown > div {
		width: 100%;
	}
}
