.main {
	display: flex;
	color: antiquewhite;
}

.view__filing__wrapper {
	/* width: 90%; */
	background-color: var(--white);
	padding: 35px 40px 0 40px !important;
	margin: 20px auto;
}

.view__filing__header {
	display: flex;
	justify-content: space-between;
	padding-bottom: 3px;
	/* border-bottom: 1px solid var(--light-grey-00-0); */
}

.header__title {
	color: var(--green-04);
	font-weight: 600;
	display: flex;
	align-items: flex-end;
	margin: 0;
}

.action__btns {
	margin-top: 30px;
	margin-bottom: 20px;
	display: flex;
	justify-content: flex-end;
	gap: 20px;
}

.action__btns button {
	padding: 16px 12px;
}

.download__btn {
	display: flex;
	font-size: 11px;
	align-items: center;
	background-color: var(--green-04) !important;
}

.download__btn img {
	width: 14px;
}

.download__btn p {
	margin: 0px 5px 0px 5px;
}

.complaint {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 10px;
	margin: 10px 0px 25px 0px;
}

.complaint p {
	font-size: 12px;
	margin: 0;
}

.h1__deets {
	margin: 30px 0px 0px;
}

.h1__deets__step_one {
	margin: 0px 0px 0px;
}

.h1__deets > div,
.h1__deets__step_one > div {
	margin: 30px 0px 5px;
}

.h1__deets p,
.h1__deets__step_one p
{
	font-size: 12px;
	margin: 0 0 3px;
}

.view__filing__total {
	height: 40px;
	background-color: var(--lights-03);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 15px;
}

.view__filing__total > * {
	font-size: 12px;
	padding: 0px 10px;
	margin: 0px;
	font-weight: 600;
}

.view__filing__total p:not(:last-of-type) {
	border-right: 1px solid #000;
}

.employee__button {
	margin: 0px 0px 30px auto;
}

.entries__title {
	color: var(--dark-blue-01);
	font-size: 600;
}

.entries__deets {
	margin: 0px;
	display: flex;
	align-items: flex-end;
}

.entries__deets > div {
	margin: 0px 0px 5px;
}

.entries__deets > div > img {
	width: 30%;
}

.entries__deets p {
	font-size: 12px;
	margin: 0 0 3px;
}

.entries__deets > div p:last-of-type {
	padding: 10px 0px;
	border-top: 1px solid #000;
}

.variants__deets {
	margin: 0px;
	display: flex;
	align-items: flex-end;
}

.variants__deets > div {
	margin: 0px 0px 5px;
}

.variants__deets p {
	font-size: 12px;
	margin: 0 0 3px;
}

.variants__deets a {
	font-size: 12px;
	color: var(--green-03);
	text-decoration: none;
}

.py__2 {
	padding: 8px 12px;
}

.action__button {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.action__button button {
	margin-left: 10px;
}
.action__button p {
	margin: 0px;
}

/* Corporate Annual Reports */

.tabs__container {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 5rem;
}

.tabs:not(:first-of-type) {
	padding: 3px 0px;
	margin: 0px 20px;
	font-weight: 600;
	cursor: pointer;
}

.tabs:first-of-type {
	padding: 3px 0px;
	margin: 0px 20px 0px 0px;
	font-weight: 600;
	cursor: pointer;
}

.active__tab {
	color: var(--green-03);
	border-bottom: 2px solid var(--green-03);
}

.flex__betweeen {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.flex__betweeen h2 {
	margin: 0px;
}

.flex__left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.flex__right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.form__footer {
	margin-top: 12px;
	padding-bottom: 60px;
	display: flex;
	width: calc(50% - 18px);
	margin-left: 0px;
}

.form__footer > button:last-of-type {
	margin-left: 0px;
	flex: 1;
}

.form__footer > button:first-of-type {
	margin-right: 0px;
	flex: 1;
}

.esbnImage {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	margin: auto 0px;
	object-fit: contain;
}


.radio__button_div > div {
	display: flex;
}

.radio__button_div > div > div {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.radio__button_div > div > div input {
	margin-right: 10px;
}

.radio__button_div > div > div label {
	margin-top: -10px;
}

.radio__button_div > div > div {
	margin-right: 30px;
}

.filingDetails {
	width: 100%;
	display: flex;
	padding-top: 20px;
	justify-content: flex-start;
	gap: 80px;
}

.filingDetails p {
margin-bottom: 9px;
}

.filingDetails .red {
	color: #D73E3E;
}

.filingDetails .green {
	color: var(--green-02);
}

.filingDetails .yellow {
	color: var(--yellow-02);
}