.main {
	margin-bottom: 60px;
}

.main h5 {
	font-weight: bold;
	font-size: 18px;
	line-height: 29px;
	color: var(--light-grey-08);
	margin-bottom: 30px;
}

.page__one_btn {
	display: block;
	width: 10vw;
	margin-left: auto;
	margin-right: 4px;
}

.page__two_buttons {
	display: flex;
	justify-content: flex-end;
}

.page__two_buttons button {
	width: 15%;
	min-width: 120px;
}

.page__two_buttons button:first-of-type {
	margin-right: 15px;
	background-color: var(--red-02);
	color: #fff;
	border: none;
	border-radius: 4px;
	height: 47px;
}

.radio__button_div > div {
	display: flex;
}

.radio__button_div > div > div {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}

.radio__button_div > div > div input {
	margin-right: 10px;
}

.radio__button_div > div > div label {
	margin-top: -10px;
}

.radio__button_div > div > div:first-child {
	margin-right: 30px;
}

.calculate__btn {
	margin-bottom: 60px;
}

.tranches__breakdown {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.tranches__breakdown > div {
	margin-bottom: 50px;
}

.tranches__breakdown span {
	color: var(--green-03);
}

.preview__title {
	text-align: center;
	margin-top: 30px;
}

.preview__breakdown {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 30px auto;
	width: 80%;
}

.preview__breakdown > div {
	margin-bottom: 20px;
}

.preview__breakdown > div > b {
	color: var(--secondary-09);
}

.former__assessment_amount {
	margin-top: 15px;
	color: var(--green-03);
}

.dev__fee_notice {
	margin-top: -10px;
	color: var(--green-03);
}

.doc__area {
	margin-top: 30px;
	border: 1px solid #000;
}

.notice__btns {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	gap: 8px;
}

@media (max-width: 768px) {
	.page__one_btn {
		width: 100%;
	}

	.page__two_buttons {
		display: flex;
		flex-direction: column;
	}

	.page__two_buttons button {
		width: calc(100% - 16px);
	}

	.page__two_buttons button:first-of-type {
		margin-bottom: 15px;
	}

	.tranches__breakdown {
		grid-template-columns: repeat(2, 1fr);
	}

	.preview__breakdown {
		grid-template-columns: repeat(2, 1fr);
	}
}
