.create__btn {
	padding: 0 25px;
}

.ymradio_btn {
	width: 28%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: -10px;
}

.doc__area {
	margin-top: 30px;
	border: 1px solid #000;
}

.notice__btns {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	gap: 8px;
}

.peradio_btn {
	width: 57%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: -15px;
}
.label_input {
	display: flex;
	align-items: center;
}

.radio_label {
	margin-right: 10px;
}

.mov_radio {
	margin-top: -40px;
}

input[type="radio"] {
	margin-right: 10px;
}

.dropdown_wrapper {
	display: flex;
	justify-content: space-between;
}

.billtype {
	margin-right: 25px;
}

.RdAmInput {
	width: 60%;
	margin-left: 90px;
}

.RdAmInput > label {
	margin-top: -8px;
}

.add_btn {
	width: 50%;
	margin-left: 275px;
	margin-top: 10px;
}

.adjust_table {
	display: flex;
	align-items: center;
}

.bottom_btn {
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
}

.bottom_btn_right {
	margin-right: 30%;
}

.read_only {
	/* border: 2px solid red; */
	opacity: 0.5; /* Adjust the opacity to visually indicate the disabled state */
	pointer-events: none; /* Disable mouse events */
}

.calc_btn {
	width: 245px;
}

.undertable {
	width: 120%;
	display: flex;
	justify-content: space-between;
	margin-top: 50px;
	margin-bottom: 30px;
}

.print__btn {
	width: 100%;
	margin-top: 50px;
	margin-bottom: 50px;
	display: "flex";
	align-items: center;
	justify-content: center;
	border: none;
	padding: 20px;
	font-size: 50px;
}

@media (max-width: 768px) {
	.dropdown_wrapper {
		width: 65%;
		display: block;
		justify-content: space-between;
	}
	.input {
		width: 100%;
	}

	.search__btn {
		display: block;
		width: 100%;
	}

	.details {
		flex-direction: column;
	}

	.details > div {
		margin-bottom: 15px;
		width: 45%;
	}

	.action__btns {
		flex-direction: column;
		gap: 15px;
	}

	.action__btns button {
		width: calc(100% - 16px);
	}

	.payeForm {
		flex-direction: column;
	}

	.payeForm > div > button {
		width: 100%;
		margin-top: 15px;
	}
}
