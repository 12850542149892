.account__manager {
	width: fit-content;
	margin-left: auto;
}

.account__manager span {
	font-size: 22px;
	font-weight: 600;
	font-style: italic;
}

.form label {
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: var(--light-grey-08);
}

.form label span {
	color: red;
}

.input__short {
	width: 9.9rem;
}

input[type="date"].input__short {
	width: 10.9rem;
}

.input__medium {
	width: 19.8rem;
}

.input__long {
	width: 42.5rem;
}

.form > .div__wrapper {
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid var(--secondary-02);
	width: 45.4rem;
	margin-bottom: 1.9rem;
}

.form > .div__wrapper > div:nth-of-type(odd) .input__medium {
	margin-right: 2.8rem;
}

.form > .div__wrapper > div:first-child .input__short,
.form > .div__wrapper > div:nth-child(3) .input__short {
	margin-right: 6.5rem;
}

.image__preview {
	position: absolute;
	height: 100%;
	opacity: 0.3;
}

.upload__div {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 7rem;
}

.upload__div button {
	border: none;
	background: url(../assets/upload.svg);
	margin-right: 0.9rem;
}

.upload__div label.upload__label {
	color: var(--neutrals-black);
	margin-bottom: 0;
}

.file__name {
	position: absolute;
	top: 45px;
	color: var(--primary-06);
	font-weight: bold;
	font-size: 1rem;
}

.form__footer {
	margin-top: 12px;
	padding-bottom: 60px;
	display: flex;
	width: calc(50% - 18px);
	margin-left: auto;
}

.form__footer > button:last-of-type {
	margin-left: 13px;
	flex: 1;
}

.form__footer > button:first-of-type {
	margin-right: 13px;
	flex: 1;
}

.primary__button {
	width: 255px;
}

.add_staff {
	width: 50px;
	height: 50px;
	cursor: pointer;
	padding: 13px;
	border-radius: 50%;
	position: absolute;
	left: 73%;
	top: -132px;
}

.add_staff:hover {
	box-shadow: 0px 38px 19px #f2f2f2;
	transition: 0.15s all ease-out;
	transform: scale(2);
}

.remove_staff {
	width: 50px;
	height: 50px;
	cursor: pointer;
	padding: 13px;
	border-radius: 50%;
	bottom: 54px;
	position: relative;
	top: 5px;
	right: 32px;
	border: none;
}

.back__btn {
	display: flex;
	margin-left: calc(33.33% + min(96px, 10%));
	margin-top: 25px;
	align-items: center;
	gap: 5px;
}

@media (max-width: 1439px) {
	input[type="date"].input__short {
		width: 12.9rem;
	}
	.form input,
	.form select {
		margin-bottom: 2.1rem;
	}
	.form label {
		margin-bottom: 0px;
	}
}

@media (max-width: 768px) {
	/* .form > .div__wrapper {
		width: unset;
	}

	.form > .div__wrapper > div:nth-of-type(odd) .input__medium {
		margin-right: 0px;
	}

	.form > .div__wrapper > div:first-child .input__short,
	.form > .div__wrapper > div:nth-child(3) .input__short {
		margin-right: 0px;
	}

	.anssid__input__mobile {
		display: flex;
	}

	.anssid__input__mobile,
	input {
		width: 100%;
	} */

	.form input,
	.form select {
		width: 100%;
	}

	.input__short,
	.input__medium,
	.input__long {
		width: 100%;
	}

	.back__btn {
		margin: 0;
		padding: 10px;
		width: 100%;
		background-color: var(--lights-01) !important;
	}

	.back__btn > span {
		display: none;
	}

	.account__manager {
		margin: 0;
		padding-left: 16px;
		padding-right: 16px;
		font-size: 12px;
	}

	.account__manager span {
		font-size: 16px;
	}
}
