.add__newbtns {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	border-radius: 10px;
	margin-bottom: 2rem;
	padding: 25px;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
}

.header__side {
	display: flex;
	flex-direction: row;
	/* justify-content: space-between; */
	gap: 25px;
	padding: 20px;
	position: relative;
}

.search__bar {
	width: 50rem;
}

.download__rpt {
	display: flex;
	justify-content: flex-end;
	padding: 10px;
	gap: 5px;
}

.label {
	font-size: larger;
	color: rgb(66, 118, 177);
}

.btn {
	width: 150px;
	height: 60px;
	text-align: center;
	background-color: rgba(52, 170, 148, 1);
	color: white;
	border-radius: 4px;
	border: none;
	margin-top: 20px;
	margin-bottom: 20px;
}

.center_btn {
	width: 150px;
	height: 60px;
	text-align: center;
	background-color: rgba(52, 170, 148, 1);
	color: white;
	border-radius: 4px;
	border: none;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: 200px;
}

.main {
	margin: 20px;
}

.setbtn {
	display: flex;
	gap: 10px;
}

.receipt__page {
	margin-bottom: 40px;
}

.secondary__btn {
	width: 110px;
	height: 50px;
	text-align: center;
	background-color: rgba(52, 170, 148, 1);
	color: white;
	border-radius: 4px;
	border: none;
	margin-top: 20px;
	margin-bottom: 20px;
}
