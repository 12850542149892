.companyName {
	font-size: 24px;
	font-weight: 600;
	margin: 0;
	margin-bottom: 13px;
	color: var(--grey-10);
}

.corpDeets {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 14px;
	color: var(--grey-08);
	margin-bottom: 32px;
}

.corpDeets span {
	padding: 4px 12px;
	border-radius: 4px;
	margin-left: 12px;
	margin-right: 16px;
	color: var(--grey-10);
	font-weight: 400;
}

.corpDeets p {
	margin: 0;
}

.corpDeets p:first-of-type span:first-of-type {
	background-color: var(--light-grey-01);
}

.corpDeets p:nth-of-type(2) {
	margin-left: 16px;
}

.corpDeets span:last-of-type {
	background-color: var(--green-00);
}

.form {
	margin-bottom: 41px;
}

.input__div {
	display: grid;
	grid-template-columns: repeat(4, calc(25% - 15px));
	gap: 20px;
	margin-bottom: 24px;
}

.input__div > div select,
.input__div > div input {
	width: 100%;
}

.form > button {
	display: block;
	width: 135px;
	margin-left: auto;
}

@media (max-width: 768px) {
	.wrapper {
		width: calc(100% - 16px);
	}

	.corpDeets p:first-of-type span:first-of-type,
	.corpDeets span:last-of-type {
		display: block;
		margin-left: 0;
		padding: 4px 40px 4px 5px;
	}

	.input__div {
		display: flex;
		flex-direction: column;
	}

	.form > button {
		width: 100%;
	}
}
